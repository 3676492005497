<ion-app>
    <ion-split-pane [when]="globalService.splitPane" content-id="main">

        <ion-menu side="start" menuId="delete" content-id="main">
            <ion-header>
                <ion-toolbar>
                    <ion-title>Design Vorlagen</ion-title>
                </ion-toolbar>
            </ion-header>
            <ion-content>
                <ion-list>
                    <ion-item-divider>
                        <ion-label>Allg.</ion-label>
                    </ion-item-divider>
                    <ion-menu-toggle auto-hide="false">
                        <ion-item  [routerLink]="['/tabs']"(click)="globalService.openActiveMenu()">
                            <ion-icon slot="start" name="desktop"></ion-icon>
                            Hauptmenü
                        </ion-item>
                    </ion-menu-toggle>
                    <ion-item-divider>
                        <ion-label>Finance</ion-label>
                    </ion-item-divider>
                    <ion-menu-toggle auto-hide="false" *ngFor="let p of globalService.walletPages">
                        <ion-item [routerLink]="[p.url]">
                            <ion-icon slot="start" [name]="p.icon"></ion-icon>
                            <ion-label>
                                {{p.title}}
                            </ion-label>
                        </ion-item>
                    </ion-menu-toggle>

                    <ion-item-divider>
                        <ion-label>Organizers</ion-label>
                    </ion-item-divider>
                    <ion-menu-toggle auto-hide="false" *ngFor="let p of globalService.organizerPages">
                        <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
                            <ion-icon slot="start" [name]="p.icon"></ion-icon>
                            <ion-label>
                                {{p.title}}
                            </ion-label>
                        </ion-item>
                    </ion-menu-toggle>

                    <ion-item-divider>
                        <ion-label>Intro and Login</ion-label>
                    </ion-item-divider>
                    <ion-menu-toggle auto-hide="false" *ngFor="let p of globalService.introPages">
                        <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
                            <ion-icon slot="start" [name]="p.icon"></ion-icon>
                            <ion-label>
                                {{p.title}}
                            </ion-label>
                        </ion-item>
                    </ion-menu-toggle>

                    <ion-item-divider>
                        <ion-label>Walkthroughs</ion-label>
                    </ion-item-divider>
                    <ion-menu-toggle auto-hide="false" *ngFor="let p of globalService.walkthroughPages">
                        <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
                            <ion-icon slot="start" [name]="p.icon"></ion-icon>
                            <ion-label>
                                {{p.title}}
                            </ion-label>
                        </ion-item>
                    </ion-menu-toggle>

                    <ion-item-divider>
                        <ion-label>Profile</ion-label>
                    </ion-item-divider>
                    <ion-menu-toggle auto-hide="false" *ngFor="let p of globalService.profilePages">
                        <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
                            <ion-icon slot="start" [name]="p.icon"></ion-icon>
                            <ion-label>
                                {{p.title}}
                            </ion-label>
                        </ion-item>
                    </ion-menu-toggle>

                    <ion-item-divider>
                        <ion-label>Account</ion-label>
                    </ion-item-divider>
                    <ion-menu-toggle auto-hide="false" *ngFor="let p of globalService.accountPages">
                        <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
                            <ion-icon slot="start" [name]="p.icon"></ion-icon>
                            <ion-label>
                                {{p.title}}
                            </ion-label>
                        </ion-item>
                    </ion-menu-toggle>

                    <ion-item-divider>
                        <ion-label>Forms</ion-label>
                    </ion-item-divider>
                    <ion-menu-toggle auto-hide="false" *ngFor="let p of globalService.formPages">
                        <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
                            <ion-icon slot="start" [name]="p.icon"></ion-icon>
                            <ion-label>
                                {{p.title}}
                            </ion-label>
                        </ion-item>
                    </ion-menu-toggle>

                    <ion-item-divider>
                        <ion-label>Cloud</ion-label>
                    </ion-item-divider>
                    <ion-menu-toggle auto-hide="false" *ngFor="let p of globalService.cloudPages">
                        <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
                            <ion-icon slot="start" [name]="p.icon"></ion-icon>
                            <ion-label>
                                {{p.title}}
                            </ion-label>
                        </ion-item>
                    </ion-menu-toggle>

                    <ion-item-divider>
                        <ion-label>Events</ion-label>
                    </ion-item-divider>
                    <ion-menu-toggle auto-hide="false" *ngFor="let p of globalService.eventPages">
                        <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
                            <ion-icon slot="start" [name]="p.icon"></ion-icon>
                            <ion-label>
                                {{p.title}}
                            </ion-label>
                        </ion-item>
                    </ion-menu-toggle>

                    <ion-item-divider>
                        <ion-label>Catalogs</ion-label>
                    </ion-item-divider>
                    <ion-menu-toggle auto-hide="false" *ngFor="let p of globalService.catalogPages">
                        <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
                            <ion-icon slot="start" [name]="p.icon"></ion-icon>
                            <ion-label>
                                {{p.title}}
                            </ion-label>
                        </ion-item>
                    </ion-menu-toggle>

                    <ion-item-divider>
                        <ion-label>Empty States</ion-label>
                    </ion-item-divider>
                    <ion-menu-toggle auto-hide="false" *ngFor="let p of globalService.emptyStatePages">
                        <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
                            <ion-icon slot="start" [name]="p.icon"></ion-icon>
                            <ion-label>
                                {{p.title}}
                            </ion-label>
                        </ion-item>
                    </ion-menu-toggle>

                    <ion-item-divider>
                        <ion-label>Components</ion-label>
                    </ion-item-divider>
                    <ion-menu-toggle auto-hide="false" *ngFor="let p of globalService.componentPages">
                        <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
                            <ion-icon slot="start" [name]="p.icon"></ion-icon>
                            <ion-label>
                                {{p.title}}
                            </ion-label>
                        </ion-item>
                    </ion-menu-toggle>

                    <ion-item-divider>
                        <ion-label>Enhanced Lists</ion-label>
                    </ion-item-divider>
                    <ion-menu-toggle auto-hide="false" *ngFor="let p of globalService.enhancedListPages">
                        <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
                            <ion-icon slot="start" [name]="p.icon"></ion-icon>
                            <ion-label>
                                {{p.title}}
                            </ion-label>
                        </ion-item>
                    </ion-menu-toggle>

                    <ion-item-divider>
                        <ion-label>Ionic Lists</ion-label>
                    </ion-item-divider>
                    <ion-menu-toggle auto-hide="false" *ngFor="let p of globalService.ionicListPages">
                        <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
                            <ion-icon slot="start" [name]="p.icon"></ion-icon>
                            <ion-label>
                                {{p.title}}
                            </ion-label>
                        </ion-item>
                    </ion-menu-toggle>
                </ion-list>
            </ion-content>
        </ion-menu>


        <ion-menu side="start" menuId="debug" content-id="main">

            <ion-menu-toggle auto-hide="false">
                <ion-item  [routerLink]="['/tabs']"(click)="globalService.openActiveMenu()">
                    <ion-icon slot="start" name="menu"></ion-icon>
                    Hauptmenü
                </ion-item>
            </ion-menu-toggle>
            <ion-header>
                <ion-toolbar color="secondary">
                    <ion-title>Debug Optionen</ion-title>
                </ion-toolbar>
            </ion-header>
            <ion-content>
                <ion-menu-toggle auto-hide="false">
                    <ion-item  (click)="globalService.displayPre = !globalService.displayPre">
                        <ion-icon slot="start" name="bug"></ion-icon>
                        Toggle Display Pre
                    </ion-item>

                    <ion-item  (click)="globalService.toggleSplitePane()">
                        <ion-icon slot="start" name="today"></ion-icon>
                        Toggle Splitpane
                    </ion-item>

                    <ion-item (click)="globalService.openDesignTemplateMenu()">
                        <ion-icon slot="start" name="color-palette"></ion-icon>
                        Design Vorlagen
                    </ion-item>
                </ion-menu-toggle>
            </ion-content>

        </ion-menu>
        <ion-menu side="start" menuId="menu" content-id="main">
            <ion-header>
                <ion-toolbar color="primary">
                    <ion-title>Menü</ion-title>
                </ion-toolbar>
            </ion-header>
            <ion-content>
                <ion-menu-toggle auto-hide="false">
                    <ion-item [routerLink]="['/my-profile']" *ngIf="authService.role === authService.UserRole.Admin || authService.role === authService.UserRole.Leader">
                        <ion-icon slot="start" name='person' color="primary"></ion-icon>
                        <ion-label>{{'Profile' | translate}}</ion-label>
                    </ion-item>
                    <ion-item [routerLink]="['/about']">
                        <ion-icon slot="start" name='body' color="primary"></ion-icon>
                        <ion-label>{{'ABOUT' | translate}}</ion-label>
                    </ion-item>
                    <ion-item (click)="openWebPageAndCloseMenu('URL_DATA_PROTECTION')" detail>
                        <ion-icon slot="start" name='hand-left' color="primary"></ion-icon>
                        <ion-label>{{'DATA_PROTECTION' | translate}}</ion-label>
                    </ion-item>
<!--                    <ion-item *ngIf="globalService.lang == 'de'" (click)="globalService.lang = 'en'">-->
<!--                        <ion-icon slot="start" name='book' color="primary"></ion-icon>-->
<!--                        <ion-label>{{'SWITCH_EN' | translate}}</ion-label>-->
<!--                    </ion-item>-->
                    <ion-item *ngIf="globalService.lang == 'en'" (click)="globalService.lang = 'de'">
                        <ion-icon slot="start" name='book' color="primary"></ion-icon>
                        <ion-label>{{'SWITCH_DE' | translate}}</ion-label>
                    </ion-item>
                    <ion-item  (click)="globalService.setTheme(true)" *ngIf="authService.role">
                        <ion-icon slot="start" name="moon"></ion-icon>
                       {{globalService.theme !== 'light' ? 'Light Mode' :'Dark Mode'}}
                    </ion-item>

                    <ion-item button (click)="authService.toggleLogin()">
                        <ion-icon slot="start" [name]="authService.isLoggedIn() ? 'log-out' : 'log-in'" color="danger"></ion-icon>
                        <ion-label>{{authService.isLoggedIn() ? 'Logout' : 'Login'}}</ion-label>
                    </ion-item>
                    <div class="ion-padding-top">
                        <ion-item color="light">
                            <ion-icon slot="start" name="time"></ion-icon>
                            App Version: {{globalService.appVersion}}
                        </ion-item>
                        <ion-item color="light">
                            <ion-icon slot="start" name="person"></ion-icon>
                            User: {{authService.username}}
                        </ion-item>
                    </div>
                </ion-menu-toggle>
            </ion-content>

        </ion-menu>

        <ion-router-outlet id="main" ></ion-router-outlet>
    </ion-split-pane>

</ion-app>

