import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {OneSignal} from '@ionic-native/onesignal/ngx';
import {Keyboard} from '@ionic-native/keyboard/ngx';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import {SafariViewController} from '@ionic-native/safari-view-controller/ngx';
import {JwtModule} from '@auth0/angular-jwt';
import {JwtHttpInterceptor} from './interceptor/jwt-http-interceptor';
import {CreateUserComponent} from './popover/create-user/create-user.component';
import {IonicSelectableModule} from 'ionic-selectable';
import {MomentModule} from 'ngx-moment';
import {FormsModule} from '@angular/forms';
import {ColorPickerModule} from 'ngx-color-picker';
import {DocumentFilesPage} from './modal/document-files/document-files.page';
import { Camera } from '@ionic-native/camera/ngx';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TreeModule} from 'angular-tree-component';
import {EditorModule, TINYMCE_SCRIPT_SRC} from '@tinymce/tinymce-angular';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

// token wird ueber this.authService.token geladen -> ""
export function tokenGetter() {
    return '';
}

@NgModule({
    declarations: [AppComponent, CreateUserComponent, DocumentFilesPage],
    entryComponents: [CreateUserComponent, DocumentFilesPage],
    imports: [BrowserModule, BrowserAnimationsModule, TreeModule.forRoot(),  IonicModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient]
            }
        }),
        JwtModule.forRoot({
            config: { tokenGetter,
            }
        }),
        MomentModule, FormsModule, ColorPickerModule,
        IonicSelectableModule, HttpClientModule, AppRoutingModule, EditorModule],
        // HttpClientModule, AppRoutingModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtHttpInterceptor,
            multi: true
        },
        StatusBar,
        SplashScreen,
        OneSignal,
        Camera,
        Keyboard,
        ScreenOrientation,
        SafariViewController,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
