import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {ModalController} from '@ionic/angular';
import {Attachment} from './event.model';
import {UserModel} from './user.model';

// https://zp.informatik.tirol/issues/12564
export enum MessageTypes {
    private = 'private',
    reminder = 'reminder',
    assessment = 'assessment',
    evaluation = 'evaluation',
    assignmentUser = 'assignmentUser',
    assignmentMentor = 'assignmentMentor',
    assignmentInvestor = 'assignmentInvestor',
    assignmentOther = 'assignmentOther',
    feedback = 'feedback',
    addQuota = 'addQuota',
    quotaRequest = 'quotaRequest',
    quotaResponse = 'quotaResponse'
}

export enum MessageTransmitType {
    Push = 'Push',
    Email = 'Email',
    PushAndEmail = 'PushAndEmail',
    inApp = 'inApp'

}
export class MessageModel {

    id: number;
    user: string;

    sender: string;
    tstamp: number;
    title: string;
    subtitle: string;
    message: string;
    jumpType: string;
    jumpTypeValue: string;
    transmitType?: MessageTransmitType;
    messageType: MessageTypes;
    readonly: boolean;
    parentMessageId?: any;
    private _read: boolean; // message.user === '/users/' + authService.user.id  -> eigene
    tstampBuffer: number;
    tstampMessageSend: number;
    attachments?: Attachment[];
    send: boolean;
    childMessageId: number; // wenn 0, dann letzte nachricht
    data: any;
    // non persistent
    // Wird von service gesetzt   resp.userRegardModel = resp.isSender ? this.userNamesMap.get(resp.user) : this.userNamesMap.get(resp.sender);
    userRegardModel: UserModel;
    isSender: boolean;
    isShown: boolean;
    groupId: any;
    feedback: boolean;
    pushOnly: any;

    // fuer messageToProgram


    /**
     * falls selber absender, dann read == true -> wird angezeigt falls erste nachricht eines chats.. Read bezieht sich auf gegenüber
     */
    get read(): boolean {
        if (this.isSender) {
            return true;
        }
        return this._read;
    }

    set read(value: boolean) {
        this._read = value;
    }

    getAvatarImg(apiURL): string {

        let img = '';
        switch (this.messageType) {
            case MessageTypes.reminder:
                img = environment.defaultCalendarImg;
                break;
            case MessageTypes.assessment:
                img = environment.defaultAssessmentImg;
                break;
            case MessageTypes.evaluation:
                img = environment.defaultAssessmentImg;
                break;
            case MessageTypes.assignmentMentor:
            case MessageTypes.assignmentInvestor:
            case MessageTypes.assignmentUser:
                img = environment.companyUserImg;
                break;
            default:
                img = this.userRegardModel ? this.userRegardModel.getAvatarImg(apiURL) : environment.defaultAvatarImg;

                break;
        }
        return img;
    }

    getICON(): string {
        let icon = '';
        switch (this.messageType) {
            case MessageTypes.private:
                icon = 'mail';
                break;
            case MessageTypes.reminder:
                icon = 'calendar';
                break;
            case MessageTypes.assessment:
                icon = 'pricetags';
                break;
            case MessageTypes.evaluation:
                icon = 'trending-up';
                break;
            case MessageTypes.assignmentUser:
                icon = 'contacts';
                break;
            case MessageTypes.assignmentMentor:
                icon = 'contacts';
                break;
            case MessageTypes.assignmentInvestor:
                icon = 'contacts';
                break;
            default:
                icon = 'mail';
        }
        return icon;
    }

    async onClickAction(router: Router, modalController: ModalController, modalComponent) {
        console.log('#12578 Jump Actions fuer Notifications (onClickAction)');
        switch (this.messageType) {
            case MessageTypes.private:
                const modal = await modalController.create({
                    component: modalComponent,
                    componentProps: {id: this.id, message: this}
                });
                return await modal.present();
                break;
            case MessageTypes.feedback:
                if (this.jumpType === 'program') {
                    await router.navigate(['/read-feedback', this.jumpTypeValue, this.sender.split('/').slice(-1)[0] ]);
                } else if (this.send === true) {
                    await router.navigate(['/program-feedback', this.jumpTypeValue]);
                } else {
                    const modal1 = await modalController.create({
                        component: modalComponent,
                        componentProps: {id: this.id, message: this}
                    });
                    return await modal1.present();
                }
                break;
            case MessageTypes.reminder:
                await router.navigate(['/tabs/calendar']);
                break;
            case MessageTypes.assessment:
                await router.navigate(['/tabs']);
                break;
            case MessageTypes.evaluation:
                router.navigate(['/tabs']);
                break;
            case MessageTypes.assignmentUser:
                router.navigate(['/tabs']);
                break;
            case MessageTypes.assignmentMentor:
                router.navigate(['/tabs']);
                break;
            case MessageTypes.assignmentInvestor:
                router.navigate(['/tabs']);
                break;
            case MessageTypes.quotaRequest:
                const modal2 = await modalController.create({
                    component: modalComponent,
                    componentProps: {id: this.id, message: this}
                });
                return await modal2.present();
                break;
            case MessageTypes.quotaResponse:
                router.navigate(['/tabs']);
                break;
            default:
                router.navigate(['/tabs']);
        }
    }
}
