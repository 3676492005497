import {Injectable} from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest, HttpHeaders
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../service/auth.service';

@Injectable()
export class JwtHttpInterceptor implements HttpInterceptor {

    constructor() {
    }

    /**
     * z.B: postRequestResetUserPassword oder uploadfiles werden nicht intercepted
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.headers.has('Authorization') &&  !req.headers.has('Content-Type')) {
            // const token = this.authService ? this.authService.token : localStorage.getItem('token'); // fuer constructor call AuthService
            // inject your AuthService here using Injector
            const token =  localStorage.getItem('token'); // fuer constructor call AuthService
            let headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
            headers = headers.append('Content-Type', 'application/json; charset=utf-8');
            headers = headers.append('Accept', 'application/json');
            // headers = headers.append('Cache-Control', 'no-cache');

            req = req.clone({
                headers
            });
            return next.handle(req);
        }
        return next.handle(req);
    }
}
