import {Injectable} from '@angular/core';
import {interval, Observable, of, Subject, Subscription, throwError} from 'rxjs';
import {catchError, delay, map, retryWhen, timeout} from 'rxjs/operators';
import {EventModel, EventTypes} from '../models/event.model';
import {GlobalService} from './global.service';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {ModalController} from '@ionic/angular';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {Company, GroupState, TageinsGroup, UserModel, UserRole} from '../models/user.model';
import {IntentionCategoryModel} from '../models/intentionCategory.model';

@Injectable({
    providedIn: 'root'
})
export class BaseService {


    protected _users: UserModel[];


    public user: any;


    public groupId: string;
    public _selectedgroupId = '';


    public subjectEventsUpdate = new Subject<EventModel>();

    public UserRole = UserRole;
    public UserModel = UserModel;



    subscription: Subscription;

    constructor(public globalService: GlobalService, public authService: AuthService,
                protected http: HttpClient,
                public modalController: ModalController,
                protected router: Router) {


        // const source = interval(60000 * 10); // every 5 minutes
        // this.subscription = source.subscribe(val => this.fetchTageinsGroups().subscribe());




    }




    public apiPlatformId(apiId: string): string {
        return apiId.split('/').slice(-1)[0];
    }


}
