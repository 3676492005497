import {Component} from '@angular/core';

import {Config, MenuController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {GlobalService} from './service/global.service';
import {OneSignal} from '@ionic-native/onesignal/ngx';
import {Keyboard} from '@ionic-native/keyboard/ngx';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import {AuthService} from './service/auth.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent {

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        public globalService: GlobalService,
        private oneSignal: OneSignal,
        public keyboard: Keyboard,
        public config: Config,
        private translate: TranslateService,
        private screenOrientation: ScreenOrientation,
        public menu: MenuController,
        public authService: AuthService,
        public router: Router,

    ) {
        this.initializeApp();
    }


    private setSystemLangConfigs() {
        this.translate.get('BACK').subscribe(backLabel => {
            this.config.set('backButtonText', backLabel);
        });
    }

    initializeApp() {
        this.globalService.setTheme(false);
        this.platform.ready().then(() => {
            console.log(this.platform.platforms());
            if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
                this.globalService.isApp = false;
                //   console.log("Wird nicht APP erkannt")
            } else {
                this.globalService.isApp = true;
                if (this.platform.is('ios')) {
                    this.globalService.isIOS = true;
                }
                //    console.log("Wird als APP erkannt")
            }

            this.translate.addLangs(this.globalService.langArray);
            this.translate.setDefaultLang(this.globalService.defaultLang);
            let lang = localStorage.getItem('lang');
            if (!lang) {
                lang = this.translate.getBrowserLang();
            }
            this.globalService.lang = lang.match(this.globalService.langMatcher) ? lang : 'de';

            if (this.platform.is('ios')) {
                // this.globalService.isIOS = true; // nur app
                this.setSystemLangConfigs();
                this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                    // this.textDir = event.lang == 'ar'? 'rtl' : 'ltr';
                    this.setSystemLangConfigs();
                });
            }

            if (this.platform.is('android')) {
                this.keyboard.hideFormAccessoryBar(false);
            }

            if (this.platform.is('cordova')) {
                this.statusBar.styleDefault();
                this.splashScreen.hide();
                if (this.platform.is('ios')) {
                    this.keyboard.hideFormAccessoryBar(false); // #11940 Nativ Keyboard Navigationsfehler IONIC 4
                }
            }


            this.platform.backButton.subscribe(() => {
                console.log('back button APP');
            });


            if (this.globalService.isApp) {
                this.platform.ready().then(() => {

                    if (this.platform.width() < 700) {
                        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
                    }
                    this.oneSignal.startInit('4f5809c7-872e-4b6b-99f1-21063b2607f4', '769751485590');
                    // this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);
                    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);
                    this.oneSignal.setSubscription(true);
                    this.oneSignal.handleNotificationReceived().subscribe(data => {
                        console.log('handleNotificationReceived: ' + JSON.stringify(data));
                        // TODO AJ
                    }, error => {
                        console.error(error);
                    });
                    this.oneSignal.handleNotificationOpened().subscribe(data => {
                        console.log('handleNotificationOpened: ' + JSON.stringify(data));
                        if (data.notification.payload.hasOwnProperty('additionalData') &&
                            data.notification.payload.additionalData.hasOwnProperty('type') &&
                            data.notification.payload.additionalData.type === 'task') {
                            setTimeout(() => this.globalService.addUserTask(), 1000);

                        } else {
                            setTimeout(() => this.globalService.addUserTask(), 1000);
                        }
                        this.router.navigate(['/tabs/mailbox']);


                    });
                    this.oneSignal.getIds().then((ids) => {
                        localStorage.setItem('pushUserId', ids.userId);
                        localStorage.setItem('pushToken', ids.pushToken);
                    });

                    this.oneSignal.endInit();
                });
            }
        });
    }

    openWebPageAndCloseMenu(url) {
        if (this.globalService.isApp) {
            this.menu.close().then(() => {
                this.globalService.openWebPageTranslateURL(url);
            }).catch(e => console.error(e));
        } else {
            this.globalService.openWebPageTranslateURL(url);
            this.menu.close();
        }
    }
}
