export enum DOCUMENT_TYPES {
    HEADLINE = 'HEADLINE',
    TEXT = 'TEXT',
    MESSAGE = 'MESSAGE',
    PICTURE = 'PICTURE',
    GALLERY = 'GALLERY',
    PAGEBREAK = 'PAGEBREAK',
}

export enum DOCUMENT_MESSAGE_TYPES {
    information = 'information',
    warning = 'warning',
    error = 'error',
    idea = 'idea',
    question = 'question',
}

export enum DOCUMENT_TITLE_POS {
    bottom = 'bottom',
    right = 'right',
}


export const DOCUMENT_TYPE_ICONS = {
    HEADLINE : 'BRUSH',
    TEXT : 'DOCUMENT-TEXT',
    MESSAGE : 'TEXT',
    PICTURE : 'IMAGE',
    GALLERY : 'IMAGES',
    PAGEBREAK : 'RETURN-DOWN-BACK',
};

export class DocumentPart {
    static types  = [
        DOCUMENT_TYPES.HEADLINE,
        DOCUMENT_TYPES.TEXT,
        DOCUMENT_TYPES.MESSAGE,
        DOCUMENT_TYPES.PICTURE,
        DOCUMENT_TYPES.GALLERY,
        DOCUMENT_TYPES.PAGEBREAK,
    ];

    static messageTypes = [
        DOCUMENT_MESSAGE_TYPES.information,
        DOCUMENT_MESSAGE_TYPES.warning,
        DOCUMENT_MESSAGE_TYPES.error,
        DOCUMENT_MESSAGE_TYPES.idea,
        DOCUMENT_MESSAGE_TYPES.question,
    ];

    static titlePos = [
        DOCUMENT_TITLE_POS.bottom,
        DOCUMENT_TITLE_POS.right
    ];


    id: number;
    type = DOCUMENT_TYPES.TEXT;
    orderNr = 0;
    title = '';
    titleLevel = 1;
    data: any;
    disabled = false;
    document: any;
    hasError: false;
    html: any;
}


export class DocumentKeywords {
    display: string;
    value: string;
    name: string;
    id: string;
}

export class DocumentFile {
    id: number;
    user: string;
    filename: string;
    filenameWithRelPath: string;
    filenameWithRelPathSmall: string;
    uploadPath: string;
    description: string;
    domain?: any;
    name: string;
    tstamp: number;
}

export class WlDocument {
    id: number;
    name = '';
    status: string;
    tstampCreation: number;
    tstampPublished: number;
    tstampLastUpdate: number;
    owner: string;
    category: string;
    keywords: any[];
    keywordsRaw: any[];
    parts: DocumentPart[];
    files: DocumentFile[];
    filename: string;
}



export class DocumentCategories {
    id: number;
    name: string;
    longName: string;
    children: DocumentCategories[];
    parent: string;
}


export interface DocumentBackup {
    id: number;
    uuid: string;
    tstamp: number;
    user: string;
    path: string;
}
