import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GlobalService} from './global.service';
import {environment} from '../../environments/environment';
import {JwtHelperService} from '@auth0/angular-jwt';
import {MenuController, NavController} from '@ionic/angular';
import {UserModel, UserRole} from '../models/user.model';
import {interval, Observable, of, Subject, Subscription, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {MessageModel, MessageTypes} from '../models/message.model';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    get userId(): string {
        return this._userId;
    }

    public subjectFetchNames = new Subject<UserModel[]>();
    public subjectLogout = new Subject();
    public subjectCurrentUser = new Subject<UserModel>();

    set ndaAccepted(value: boolean) {
        this._ndaAccepted = value;
        localStorage.setItem('ndaAccepted', '' + value);

    }

    get ndaAccepted(): boolean {
        return this._ndaAccepted;
    }


    private _ndaAccepted = false;

    private _userId = '';

    public UserRole = UserRole;

    private _apiUrl: string = null;
    public company: string = null;
    private _role: string = null;
    private _token: string = null;
    private _rt: string = null;
    private _user: UserModel;
    private _username: string = null;

    public MessageTypes = MessageTypes;
    public messages: MessageModel[];
    public messagesMap = new Map<string, MessageModel>();

    public userNames: UserModel[]; // e.g. for create events ->hosts usw..
    public userNamesMap = new Map<string, UserModel>();

    public showAssessments = false;
    public showInitiatives = true;
    public showPrograms = true;
    public showMentors = true;
    public showInvestors = true;

    public showMine = true;
    public showMentor = false;
    public showInvestor = false;
    public showCompany = true;
    public showRelevance = false;


    subscription: Subscription;

    // tslint:disable-next-line:max-line-length
    constructor(public http: HttpClient, public globals: GlobalService, public jwtHelper: JwtHelperService,
                public  navCtrl: NavController, public menu: MenuController, protected translateService: TranslateService,
    ) {
        this._ndaAccepted = localStorage.getItem('ndaAccepted') === 'true';
        this._token = localStorage.getItem('token');
        this._rt = localStorage.getItem('rt');
        this._role = localStorage.getItem('role');
        this._apiUrl = localStorage.getItem('apiUrl');
        this.company = localStorage.getItem('company');
        this._username = localStorage.getItem('username');
        this._userId = localStorage.getItem('userId');
        if (this._token) {
            this.menu.enable(true);
            this.getCurrentUser().subscribe();

        } else {
            this.menu.enable(false);
        }
        if (this._token) {
            this.fetchUsersNames().subscribe();
        }

        const source = interval(60000 * 10); // every 10 minutes #12708
        this.subscription = source.subscribe(val => this.getCurrentUser().subscribe());

    }


    get apiUrl() {
        return this._apiUrl;
    }

    getUserRoleEnum() {
        return UserRole;
    }

    get role(): string {
        return this._role;
    }

    set role(value: string) {
        this._role = value;
        if (!value) {
            localStorage.removeItem('role');
        } else {
            localStorage.setItem('role', value);
        }
    }

    get token(): string {
        return this._token;
    }

    set token(value: string) {
        this._token = value;
        if (!value) {
            localStorage.removeItem('token');
        } else {
            localStorage.setItem('token', value);
        }
        if (!value) {
            this.role = null;
            this.user = null;
        } else {
            const decodedToken = this.jwtHelper.decodeToken(value);
            this.role = decodedToken.roles['0'];
        }
    }

    get rt(): string {
        return this._rt;
    }

    set rt(value: string) {
        this._rt = value;
        if (!value) {
            localStorage.removeItem('rt');
        } else {
            localStorage.setItem('rt', value);
        }
    }

    get user(): UserModel {
        return this._user;
    }

    set user(value: UserModel) {
        this._user = value;
    }

    get username(): string {
        return this._username;
    }

    set username(value: string) {
        this._username = value;
        localStorage.setItem('username', value);

    }

    /**
     * Local aj: company aj, Local lt: lt
     */
    buildApiUrl(company) {

        if (!company) {
            this._apiUrl = 'https://' + environment.URL;
            return true;
        }
        if (company === 'debug') {
            this.globals.isDebug = !this.globals.isDebug;
            localStorage.setItem('isDebug', String(this.globals.isDebug));
            return false;
        }

        if (company === 'aj') {
            this._apiUrl = 'https://' + environment.TESTURLAJ;
        } else if (company === 'lt') {
            this._apiUrl = 'https://' + environment.TESTURLLT;
        } else if (company === 'test') {
            this._apiUrl = 'https://' + environment.TESTURL_WOHLLAIB;
        } else if (company === environment.COMPANY) {
            this._apiUrl = 'https://' + environment.URL;
        } else {
            this._apiUrl = 'https://' + company + '.' + environment.URL;
        }
        localStorage.setItem('apiUrl', this._apiUrl);

        this.company = company;

        return true;
    }

    comanyString() {
        if (this.company === environment.COMPANY) {
            return '';
        }
        return '(' + this.company + ')';
    }


    logout() {
        // Api Token Logout
        this.token = null;
        this.user = null;
        localStorage.removeItem('apiUrl');
        this.menu.close();
        this.menu.enable(false);
        // this.username = '';
        this.subjectLogout.next(''); // anstatt  deprecated this.events.publish('logout');;

        this.navCtrl.navigateRoot('/welcome');

    }

    toggleLogin() {
        if (this.isLoggedIn()) {
            this.logout();

        } else {
            this.navCtrl.navigateForward('/login');
        }
    }

    isLoggedIn() {
        const token = this.token;
        if (!token) {
            return false;
        }
        return !this.jwtHelper.isTokenExpired(token);
    }

    /**
     * Login
     * Übermittelt die Pushid
     */
    loginPost(user, password) {
        debugger;
        const pushUserId = localStorage.getItem('pushUserId');
        const postData = {
            pushUserId,
            username: user,
            password,
            isTest: this.globals.isTest,
        };
        return new Promise((resolve, reject) => {
            // this.http.post(this.apiUrl + "appLoginPost", JSON.stringify(credentials),{ headers: headers}).
            this.http.post(this.apiUrl + environment.REST_PATH + 'login', JSON.stringify(postData)).subscribe(res => {
                const result: any = res;
                this.token = result.token;
                this.rt = result.refresh_token;
                this.getCurrentUser().subscribe(() => {
                    this.fetchUsersNames().subscribe(); // fetchUsersNames benötigt currentUser /
                });

                resolve(res);
            }, (err) => {
                // alert(err);
                reject(err);

            });

        });

    }

    registerPost(data, globalService: GlobalService) {

        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.append('Accept', 'application/json');

        return new Promise((resolve, reject) => {
            // this.http.post(this.apiUrl + "appLoginPost", JSON.stringify(credentials),{ headers: headers}).
            this.http.post(this.apiUrl + environment.REST_PATH + 'registerUser', JSON.stringify(data), {headers}).subscribe(res => {
                globalService.presentSuccessToast('Thank you, Check your inbox');
                resolve(res);
            }, (err) => {
                console.log(err);
                if (err.error.detail) {
                    globalService.presentErrorToastNoTranslate(err.error.detail);
                } else {
                    globalService.presentErrorToast('Something went wrong');
                }
                reject(err);

            });

        });

    }


    /**
     * gibt den user zurueck und uebergibt per get die PushId
     */
    getCurrentUser(): Observable<UserModel> {
        if (!this.isLoggedIn()) {
            return of(null);
        }
        let pushUserId = '';
        if (localStorage.getItem('pushUserId')) {
            pushUserId = '?pushUserId=' + localStorage.getItem('pushUserId');
        }

        return this.http
            .get<UserModel>(this.apiUrl + `currentUser${pushUserId}`)
            .pipe(
                map(response => {
                    const cu = response[0] as UserModel;

                    if (cu.backgroundData.numUnreadMessagesUsers) {
                        cu.backgroundData.numUnreadMailbox = 0;
                        for (const i in cu.backgroundData.numUnreadMessagesUsers) {
                            if (i !== '0' && i !== this._userId) { // 0 -> alle notifications welche nicht private sind
                                cu.backgroundData.numUnreadMailbox += cu.backgroundData.numUnreadMessagesUsers[i];
                            }
                        }
                    }

                    this.user = cu;
                    // console.log(cu);
                    this._userId = '' + this.user.id;
                    localStorage.setItem('userId', String(this.user.id)); // falls userId zur ladezeit (pageReload) benötigt wird
                    this.subjectCurrentUser.next(this.user); // anstatt  deprecated this.events.publish('currentUser');

                    return cu;
                }),
                catchError(err => {
                    this.logout();  // #12501 401 Antwort von Server -> ausloggen
                    // console.error('#12501 401 Antwort von Server -> ausloggen');
                    console.log(err);
                    return throwError(err);
                })
            );
    }

    /**
     * fetchMessages
     * @param filterSend: true -> nur bereits vom server versendete nachrichten
     */
    public fetchMessages(filterSend: boolean = true): Observable<MessageModel[]> {
        const meUser = '/users/' + this._userId;
        let filter = '';
        let paramCC = '?';
        if (filterSend) {
            filter = '?send=true';
            paramCC = '&';
        }
        return this.http.get<MessageModel[]>(this.apiUrl + `messages${filter}`)
            .pipe(
                map(response => {
                    this.messagesMap.clear();
                    this.messages = [];
                    for (const m of response) {
                        const resp = Object.assign(new MessageModel(), m);
                        resp.isSender = resp.sender === meUser;
                        resp.userRegardModel = resp.isSender ? this.userNamesMap.get(resp.user) : this.userNamesMap.get(resp.sender);
                        this.messages.push(resp);
                        this.messagesMap.set(String(resp.id), resp);
                    }
                    return this.messages;
                }));
    }

    public fetchMessageHistory(id): Observable<MessageModel[]> {
        const meUser = '/users/' + this._userId;
        return this.http.get<MessageModel[]>(this.apiUrl + `messageHistory/${id}`)
            .pipe(
                map(response => {
                    const messageHist = [];
                    for (const m of response) {
                        const resp = Object.assign(new MessageModel(), m);
                        resp.isSender = resp.sender === meUser;
                        resp.userRegardModel = resp.isSender ? this.userNamesMap.get(resp.user) : this.userNamesMap.get(resp.sender);
                        messageHist.push(resp);
                    }
                    return messageHist;
                }));
    }

    postMessage(message: MessageModel, globalService: GlobalService): Observable<any> {
        return this.http.post(this.apiUrl + `messages`, JSON.stringify(message))
            .pipe(
                map(response => {
                    return response;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );
    }

    postMessageToProgram(message: MessageModel, globalService: GlobalService): Observable<any> {
        return this.http.post(this.apiUrl + `messageToGroup`, JSON.stringify(message))
            .pipe(
                map(response => {
                    return response;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );
    }

    postRequestQuota(userString, messageString, globalService: GlobalService): Observable<any> {
        const postData = {
            user: userString,
            message: messageString
        };
        return this.http.post(this.apiUrl + `quotaMessage`, JSON.stringify(postData))
            .pipe(
                map(response => {
                    return response;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );
    }

    postSetMessageQuota(userString, senderString, quota, globalService: GlobalService): Observable<any> {
        const postData = {
            user: userString,
            sender: senderString,
            quota
        };
        return this.http.post(this.apiUrl + `setMessageQuota`, JSON.stringify(postData))
            .pipe(
                map(response => {
                    return response;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );
    }

    /**
     * API URL wird anhand isTest gesetzt, da bei Aufruf nicht bekannt
     */
    postRequestResetUserPassword(user, globalService: GlobalService): Observable<any> {

        const apiUrl = 'https://' + (globalService.isTest ? environment.TESTURL_WOHLLAIB : environment.URL);
        const appUrl = (globalService.isTest ? environment.TEST_APP_URL : environment.APP_URL);
        const postData = {
            username: user,
            link: appUrl + '/#/reset-password'
        };
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.append('Accept', 'application/json');

        return this.http.post(apiUrl + `requestResetUserPassword`, JSON.stringify(postData), {headers})
            .pipe(
                map(response => {
                    return response;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );
    }

    /**
     * API URL wird anhand isTest gesetzt, da bei Aufruf nicht bekannt
     */
    postResetUserPassword(user, uuid, password, globalService: GlobalService): Observable<any> {
        const apiUrl = 'https://' + (globalService.isTest ? environment.TESTURL_WOHLLAIB : environment.URL);
        const postData = {
            username: user,
            uuid,
            password
        };
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.append('Accept', 'application/json');

        return this.http.post(apiUrl + `resetUserPassword`, JSON.stringify(postData), {headers})
            .pipe(
                map(response => {
                    return response;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );
    }

    /**
     * mark messages as read and update currentUser.numUnreadMessages
     */
    markMessageAsRead(message: MessageModel, globalService: GlobalService): Observable<any> {

        if (this.user.backgroundData.numUnreadMessages > 0) {
            this.user.backgroundData.numUnreadMessages--;
            if (message.messageType === MessageTypes.private) { // solange wir current user nicht erneut laden müssen wir selber rechnen
                this.user.backgroundData.numUnreadMailbox--;
                const sid = this.apiPlatformId(message.sender);
                this.user.backgroundData.numUnreadMessagesUsers[sid]--;
                if (this.user.backgroundData.numUnreadMessagesUsers[sid] === 0) {
                    delete this.user.backgroundData.numUnreadMessagesUsers[sid];
                }
            }
        }

        message.read = true;
        const putData: any = {};
        putData.id = message.id;
        putData.read = true;
        return this.http.put(this.apiUrl + `messages/${putData.id}`, JSON.stringify(putData))
            .pipe(
                map(response => {
                    return response;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );
    }


    /**
     * TODO AJ: filter User_Role bei Events
     */
    public fetchUsersNames(): Observable<UserModel[]> {

        const filter = '';
        // let paramCC = '?';
        // if (groupId !== '' && groupId !== '-') {
        //     filter = '?ProgramFilter=' + groupId;
        //     paramCC = '&';
        // }
        //
        // if (filterAllowedRoles && filterAllowedRoles !== UserRole.None) {
        //     filter += paramCC + 'allowedRoles=' + filterAllowedRoles;
        // }
        return this.http
            .get<UserModel[]>(this.apiUrl + `getNames${filter}`)
            .pipe(
                map(response => {
                    this.userNames = [];
                    for (const u of response) {
                        const user = Object.assign(new UserModel(), u);
                        // tslint:disable-next-line:max-line-length
                        user.longName = user.name + (user.surName ? ' ' + user.surName : '') + '   [' + this.translateService.instant(user.roles[0]) + ']';
                        this.userNamesMap.set('/users/' + user.id, user);
                        this.userNames.push(user);
                    }

                    this.subjectFetchNames.next(response); // anstatt  deprecated this.events.publish('fetchNames');
                    return this.userNames;
                }));
    }

    public openNotification(message: MessageModel, router: Router, page, modalController, globalService: GlobalService) {

        // tslint:disable-next-line:max-line-length
        if (message.messageType !== MessageTypes.private && message.messageType !== MessageTypes.feedback && !message.read) { // private message werden in nachrichten modal verarbeitet, da erst auf read gestellt wenn alle von history gelesen
            this.markMessageAsRead(message, globalService).subscribe();
        }
        message.onClickAction(router, modalController, page);
        console.log(message);
    }

    public apiPlatformId(apiId: string): string {
        return apiId.split('/').slice(-1)[0];
    }


    /**
     * gibt Quota zurück
     * Die Quota wird vom Client nicht selber berechnet (auch nach senden, -> neu laden von current user)
     */
    getQuotaForUser(user: UserModel): number {

        return -1;

        // if (this.user.roles[0] !== UserRole.User) {
        //     return -1;
        // }
        // if (user.roles[0] === UserRole.Admin || user.roles[0] === UserRole.Leader) {
        //     return -1;
        // } else if (this.user.quota && this.user.quota.hasOwnProperty(String(user.id))) {
        //     return this.user.quota[String(user.id)];
        // }
        // return 1;
    }
}


