import { Component, OnInit } from '@angular/core';
import {NavParams, PopoverController} from '@ionic/angular';
import {BackendService} from '../../service/backend.service';
import {UserRole} from '../../models/user.model';
import {AuthService} from '../../service/auth.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {
  type;

  constructor(
      private navParams: NavParams,
      private popoverController: PopoverController,
      public  backendService: BackendService,
      public  authService: AuthService) {

  }

  ngOnInit() {
    this.type = this.navParams.data.type;
    console.log(this.type);
  }

  public dismissPopover() {
    this.backendService.subjectClosePopOver.next(''); // this.events.publish('fromPopoverEvent');
    this.popoverController.dismiss();
  }


  addUser(user: UserRole) {
    // if (this.type === 'program') {
    //   this.backendService.postProgramAndGoto(user).subscribe(() => {
    //     this.popoverController.dismiss();
    //   });
    // } else { // user
    //   this.backendService.postUserAndGotoProfile(user).subscribe(() => {
    //     this.popoverController.dismiss();
    //   });
    // }


      this.backendService.postUserAndGotoProfile(user).subscribe(() => {
        this.popoverController.dismiss();
      });



  }
}
