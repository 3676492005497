<ion-content>

    <ion-list>
        <ion-list-header>{{'Erstellen' | translate}} {{type | translate}}</ion-list-header>
        <ion-item button (click)="addUser(backendService.UserRole.User)">{{'User' | translate}}</ion-item>
        <ion-item button (click)="addUser(backendService.UserRole.Leader)" *ngIf="authService.role == authService.UserRole.Admin">{{'Leader' | translate}}</ion-item>
        <ion-item button (click)="addUser(backendService.UserRole.Admin)" *ngIf="authService.role == authService.UserRole.Admin">{{'Admin' | translate}}</ion-item>
<!--        <ion-item *ngIf="type !== 'program'" button (click)="addUser(backendService.UserRole.Host)">{{'Host' | translate}}</ion-item>-->

    </ion-list>
    <ion-button expand="block" (click)="dismissPopover()">Schliessen</ion-button>
</ion-content>
