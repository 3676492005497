import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AlertController, LoadingController, ModalController, NavController} from '@ionic/angular';
import {AuthService} from '../../service/auth.service';
import {GlobalService} from '../../service/global.service';
import {BackendService} from '../../service/backend.service';
import {ActivatedRoute} from '@angular/router';
import {DocumentFile} from '../../models/document.model';
import {Camera, CameraOptions} from '@ionic-native/camera/ngx';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-document-files',
  templateUrl: './document-files.page.html',
  styleUrls: ['./document-files.page.scss'],
})
export class DocumentFilesPage implements OnInit {
  @ViewChild('imageFileChooser', { static: true }) imageFileChooser: ElementRef;
  isUpdating = false;

  constructor(public authService: AuthService, public globalService: GlobalService, public backendService: BackendService,
              private activatedRoute: ActivatedRoute, public navCtrl: NavController, private modalController: ModalController,
              public loadingController: LoadingController, private camera: Camera, public alertController: AlertController, private translate: TranslateService) {
  }

  async dismissModal() {
    const onClosedData = '';
    await this.modalController.dismiss(onClosedData);
  }

  async presentLoading(text) {
    this.isUpdating = true;

    const loading = await this.loadingController.create({
      message: text,
      duration: 3000
    });
    await loading.present();
  }

  async dismissLoading() {
    this.isUpdating = false;
    return await this.loadingController.dismiss();
  }

  ngOnInit() {
  }

  async selectImageAndClose(file: DocumentFile) {
    const onClosedData = file.id;
    await this.modalController.dismiss(onClosedData);
  }

  ionViewWillEnter() {
    const wireUpImageChooser = () => {
      const elementRef = this.imageFileChooser.nativeElement as HTMLInputElement;

      elementRef.addEventListener('change', async (evt: any) => {
        const files = evt.target.files as FileList;
        for (let i = 0; i < files.length; i++) {
          // #13594 Upload Bilder sequenzieren + Anzeige welches Bild hochgeladen wird
          this.presentLoading('LADE HOCH: ' + (i + 1) + '/' + files.length);
          const fileToUpload: File = files.item(i);
          await this.backendService.uploadFiles(fileToUpload, 'document', '', this.backendService.document.id).toPromise();
          this.dismissLoading();
        }

      }, false);

    };

    wireUpImageChooser();
  }

  openHTMLImageFileChooser() {
    this.imageFileChooser.nativeElement.click();
  }

  takePicture() {
    const pixel = this.authService.user.backgroundData.cameraSettings ? this.authService.user.backgroundData.cameraSettings.pixel : 1920;
    const quality = this.authService.user.backgroundData.cameraSettings ? this.authService.user.backgroundData.cameraSettings.quality : 50;
    const options: CameraOptions = {
      quality: +quality,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      targetWidth: +pixel,
      targetHeight: +pixel,
      correctOrientation: true,
    };
    this.camera.getPicture(options).then((imageData) => {
      let currentImage: any = imageData;
      currentImage = this.backendService.dataURItoBlob(currentImage);
      const imageFile = new File([currentImage], 'bild' + (this.backendService.document.files.length + 1) + '.jpg', { type: 'image/jpeg' });

      this.presentLoading('LADE HOCH ...');
      this.backendService.uploadFiles(imageFile, 'document', '', this.backendService.document.id).subscribe(docFiles => {
        this.dismissLoading();
      });
    }, (err) => {
      // Handle error
      console.log('Camera issue:' + err);
    });
  }

  async rotateImage(file, index) {
    const alert = await this.alertController.create({
      header: this.translate.instant('Bild Drehen'),
      message: this.translate.instant('Drehen betrifft alle Referenzen'),
      buttons: [
        {
          text: this.translate.instant('Abbrechen'),
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: this.translate.instant('Ok'),
          handler: () => {
            this.backendService.putDocumentFileObject(file, 90).subscribe(res => {
                this.backendService.document.files[this.backendService.document.files.length - 1 - index] = res;
            });
          }
        }
      ]
    });
    await alert.present();
  }
}
