import {Injectable} from '@angular/core';
import {GlobalService} from './global.service';
import {AuthService} from './auth.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ModalController} from '@ionic/angular';
import {Observable, Subject, throwError} from 'rxjs';
import {FileUpload, UserModel, UserRole} from '../models/user.model';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, map} from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {BaseService} from './base.service';
import {environment} from '../../environments/environment';
import {
    DOCUMENT_TYPE_ICONS,
    DOCUMENT_TYPES, DocumentBackup,
    DocumentCategories,
    DocumentFile,
    DocumentKeywords,
    DocumentPart,
    WlDocument
} from '../models/document.model';
import {IContentTags} from 'tslint/lib/rules/completed-docs/tagExclusion';
import random from '@angular-devkit/schematics/src/rules/random';
import {DomSanitizer} from '@angular/platform-browser';
import {nodeDebugInfo} from '@angular/compiler-cli/src/ngtsc/util/src/typescript';

@Injectable({
    providedIn: 'root'
})
export class BackendService extends BaseService {


    public subjectClosePopOver = new Subject();
    public subjectReloadAppData = new Subject();

    public userId;
    public vectorId;


    private _user: UserModel;
    private _userClone: UserModel;

    private _document: WlDocument;
    private _documentClone: WlDocument;

    public documents: WlDocument[] = [];
    public myDocuments: WlDocument[];
    public draftDocuments: WlDocument[];
    public deletedDocuments: WlDocument[];

    public documentKeywords: DocumentKeywords[] = [];
    public documentKeywordsMap = new Map<string, DocumentKeywords>();

    public documentCatetories: DocumentCategories;
    public documentCatetoriesMap = new Map<string, DocumentCategories>();

    public readonly defaultAvatarImg = environment.defaultAvatarImg;
    public readonly defaultImg = environment.defaultImg;
    public readonly groupImg = 'assets/imgs/tageinsCat.png';
    public readonly companyImg = 'assets/imgs/empty-tasks-3.png';
    public readonly userImg = 'assets/imgs/placeholder-profile.jpg';

    public documentEditState = false;
    public documentEditStateMetaData = false;
    public documentPreviewState = false;

    public DocumentPart = DocumentPart;
    public DOCUMENT_TYPES = DOCUMENT_TYPES;
    public DOCUMENT_TYPE_ICONS = DOCUMENT_TYPE_ICONS;
    public cameraSetting: any;


    constructor(public globalService: GlobalService, public authService: AuthService,
                protected http: HttpClient,
                public modalController: ModalController,
                protected router: Router, protected translateService: TranslateService,
                public sanitizer: DomSanitizer) {
        super(globalService, authService, http, modalController, router);
        this.authService.subjectLogout.subscribe({
            next: (v) => this.logoutCleanUp()
        });

        this.fetchDocumentsKeywords().subscribe(res => {

        });
        this.fetchDocumentsCategories().subscribe(res => {

        });
        // if (this.authService.role !== UserRole.User) {
        //     this.fetchCompanies().subscribe(() => {
        // });
        // }

    }


    get user(): UserModel {
        return this._user;
    }

    set user(value: UserModel) {
        this._user = value;
        this._userClone = value != null ? _.cloneDeep(value) : null;
    }

    get userClone(): UserModel {
        return this._userClone;
    }

    get document(): WlDocument {
        return this._document;
    }

    set document(value: WlDocument) {
        this._document = value;
        this._documentClone = value != null ? _.cloneDeep(value) : null;
    }

    get documentClone(): WlDocument {
        return this._documentClone;
    }


    private logoutCleanUp() {
        this._user = null;
        this._userClone = null;
        this.globalService.reloadApp(); // TODO AJ
    }

    public reloadAppData() {
        this.fetchDocumentsKeywords().subscribe();
        this.fetchDocumentsCategories().subscribe();
        this.authService.getCurrentUser().subscribe();
        this.authService.fetchUsersNames().subscribe();
        this.subjectReloadAppData.next('');
    }

    /**
     * für myprofile
     */
    public ngOnInitAssignMyPageParams(activatedRoute: ActivatedRoute) {
        this.userId = activatedRoute.snapshot.paramMap.get('userId');
        if (this.userId == null) { // user editiert sein profile
            this.user = this.authService.user;
            this.userId = this.user.id;
        } else {
            if (this.userId > 0 && (!this._user || (this._user.id + '') !== this.userId)) {
                this.fetchUser(this.userId).subscribe();
            }
        }
    }

    /**
     * Navigiere zu ADMIN USER
     */
    public routeToUserAdminAndInit(groupId = '-'): void {
        this.groupId = groupId;
        this.router.navigate(['/admin/user-admin/', groupId]);
        // if (groupId === '-'){
        //     this.registeredPrograms = null;
        // } else {
        //     this.fetchRegisteredPrograms(groupId).subscribe();
        // }
    }


    public fetchDocuments(owner = null, tags = null, name: string = null, catetory = null, withChilds = false, status = 'APPROVED'): Observable<WlDocument[]> {
        let filter = '?order[tstampLastUpdate]=desc';
        let paramCC = '&';
        if (name && name !== '' && name !== '-') {
            filter += paramCC + 'name=' + name;
            paramCC = '&';
        }
        if (tags && Array.isArray(tags)) {
            for (const tag of tags) {
                filter += paramCC + 'keywords[]=' + tag.value;
                paramCC = '&';
            }
        }
        if (status === 'ALL') {
                filter += '&status[]=APPROVED&status[]=DRAFT';
                paramCC = '&';

        } else {
            filter += '&status=' + status;
            paramCC = '&';
        }
        if (catetory) {
            if (withChilds) {
                filter += paramCC + 'categoryInclChilds=' + catetory;
            } else {
                filter += paramCC + 'category=' + catetory;
            }
            paramCC = '&';
        }

        // if (filterAllowedRoles && filterAllowedRoles !== UserRole.None) {
        //     filter += paramCC + 'allowedRoles=' + filterAllowedRoles;
        // }
        return this.http.get<WlDocument[]>(this.authService.apiUrl + `documents${filter}`)
            .pipe(
                map(response => {
                    this.documents = response;
                    return response;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        this.globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        this.globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );
    }

    public fetchMyDocuments(): Observable<WlDocument[]> {
        let filter = '?order[tstampLastUpdate]=desc';
        let paramCC = '&';

        filter += '&owner=/users/' + this.authService.userId;
        paramCC = '&';

        // if (filterAllowedRoles && filterAllowedRoles !== UserRole.None) {
        //     filter += paramCC + 'allowedRoles=' + filterAllowedRoles;
        // }
        return this.http.get<WlDocument[]>(this.authService.apiUrl + `documents${filter}`)
            .pipe(
                map(response => {
                    this.myDocuments = response;
                    return response;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        this.globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        this.globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );
    }

    public fetchDraftDocuments(): Observable<WlDocument[]> {
        let filter = '?order[tstampLastUpdate]=desc';
        let paramCC = '&';

        filter += '&status=draft';
        paramCC = '&';

        // if (filterAllowedRoles && filterAllowedRoles !== UserRole.None) {
        //     filter += paramCC + 'allowedRoles=' + filterAllowedRoles;
        // }
        return this.http.get<WlDocument[]>(this.authService.apiUrl + `documents${filter}`)
            .pipe(
                map(response => {
                    this.draftDocuments = response;
                    return response;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        this.globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        this.globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );
    }

    public fetchDeletedDocuments(): Observable<WlDocument[]> {
        let filter = '?order[tstampLastUpdate]=desc';
        let paramCC = '&';

        filter += '&status=REMOVED';
        paramCC = '&';

        // if (filterAllowedRoles && filterAllowedRoles !== UserRole.None) {
        //     filter += paramCC + 'allowedRoles=' + filterAllowedRoles;
        // }
        return this.http.get<WlDocument[]>(this.authService.apiUrl + `documents${filter}`)
            .pipe(
                map(response => {
                    this.deletedDocuments = response;
                    return response;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        this.globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        this.globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );
    }

    public fetchDocument(id): Observable<WlDocument> {
        this.document = null;
        return this.http.get<WlDocument>(this.authService.apiUrl + `documents/${id}`)
            .pipe(
                map(response => {
                    const doc = Object.assign(new WlDocument(), response);
                    this.document = doc;
                    return doc;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        this.globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        this.globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );
    }

    public putDocument(doc: any): Observable<WlDocument> {

        return this.http.put<WlDocument>(this.authService.apiUrl + `documents/${doc.id}`, JSON.stringify(doc))
            .pipe(
                map(response => {
                    const newDoc = Object.assign(new WlDocument(), response);
                    if (this.documents) { // Dokumentliste bei Änderung Dokument aktualisieren
                        for (const iDoc in this.documents) {
                            if (this.documents[iDoc].id === newDoc.id) {
                                this.documents[iDoc] = newDoc;
                            }
                        }
                    }
                    if (this.myDocuments) {
                        for (const iDoc in this.myDocuments) {
                            if (this.myDocuments[iDoc].id === newDoc.id) {
                                this.myDocuments[iDoc] = newDoc;
                            }
                        }
                    }
                    if (this.draftDocuments) {
                        for (const iDoc in this.draftDocuments) {
                            if (this.draftDocuments[iDoc].id === newDoc.id) {
                                this.draftDocuments[iDoc] = newDoc;
                            }
                        }
                    }
                    if (this.deletedDocuments) {
                        for (const iDoc in this.deletedDocuments) {
                            if (this.deletedDocuments[iDoc].id === newDoc.id) {
                                this.deletedDocuments[iDoc] = newDoc;
                            }
                        }
                    }

                    this.document = newDoc;
                    return newDoc;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        this.globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        this.globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );


    }

    public postDocument(docPart: any): Observable<WlDocument> {
        return this.http.post<WlDocument>(this.authService.apiUrl + `documents`, JSON.stringify(docPart))
            .pipe(
                map(response => {
                    const doc = Object.assign(new WlDocument(), response);
                    this.document = doc;
                    this.documents.push(doc);
                    return doc;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        this.globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        this.globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );


    }

    public putDocumentPart(docPart: any): Observable<WlDocument> {

        return this.http.put<WlDocument>(this.authService.apiUrl + `document_parts/${docPart.id}`, JSON.stringify(docPart))
            .pipe(
                map(response => {
                    const doc = Object.assign(new WlDocument(), response);
                    this.document = doc;
                    return doc;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        this.globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        this.globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );


    }

    public deleteDocumentPart(docPart: any, index): Observable<any> {

        return this.http.delete<any>(this.authService.apiUrl + `document_parts/${docPart.id}`)
            .pipe(
                map(response => {
                    this.document.parts.splice(index, 1);
                    this.document = this.document; // dirty state
                    // const doc = Object.assign(new WlDocument(), response);
                    // this.document = doc;
                    return this.document; // TODO NOW AJ return richtiges dok
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        this.globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        this.globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );
    }

    /**
     * derzeit nur fuer rotation
     */
    public putDocumentFileObject(file, rotation): Observable<any> {
        const putData: any = {};
        putData.rotation = rotation;
        return this.http.put<any>(this.authService.apiUrl + `file_objects/${file.id}`, JSON.stringify(putData))
            .pipe(
                map(response => {
                    return response;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        this.globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        this.globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );


    }

    public postDocumentPart(docPart: any): Observable<WlDocument> {
        return this.http.post<WlDocument>(this.authService.apiUrl + `document_parts`, JSON.stringify(docPart))
            .pipe(
                map(response => {
                    const doc = Object.assign(new WlDocument(), response);
                    this.document = doc;
                    return doc;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        this.globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        this.globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );


    }


    public fetchDocumentPDFUrl(id): Observable<string> {
        return this.http.get<any>(this.authService.apiUrl + `pdf/${id}`)
            .pipe(
                map(response => {
                    return response.url;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        this.globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        this.globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );
    }

    public fetchDocumentsKeywords(): Observable<DocumentKeywords[]> {

        const filter = '';
        const paramCC = '?';
        // if (groupId !== '' && groupId !== '-') {
        //     filter = '?TageinsGroupFilter=' + groupId;
        //     paramCC = '&';
        // }

        // if (filterAllowedRoles && filterAllowedRoles !== UserRole.None) {
        //     filter += paramCC + 'allowedRoles=' + filterAllowedRoles;
        // }
        return this.http
            .get<DocumentKeywords[]>(this.authService.apiUrl + `keywords${filter}`)
            .pipe(
                map(response => {
                    if (Array.isArray(response)) {
                        this.documentKeywords = [];
                        this.documentKeywordsMap.clear();
                        for (const kw of response) {
                            const t = new DocumentKeywords();
                            t.display = kw.name;
                            t.value = '/keywords/' + kw.id;
                            t.id = kw.id;
                            this.documentKeywords.push(t);
                            this.documentKeywordsMap.set(t.value, t);
                        }
                    }
                    return this.documentKeywords;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        this.globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        this.globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );
    }

    public fetchDocumentsCategories(): Observable<DocumentCategories> {
        const filter = '';
        const paramCC = '?';
        // if (groupId !== '' && groupId !== '-') {
        //     filter = '?TageinsGroupFilter=' + groupId;
        //     paramCC = '&';
        // }

        // if (filterAllowedRoles && filterAllowedRoles !== UserRole.None) {
        //     filter += paramCC + 'allowedRoles=' + filterAllowedRoles;
        // }
        return this.http
            .get<DocumentCategories>(this.authService.apiUrl + `categories/1${filter}`)
            .pipe(
                map(response => {

                    this.documentCatetories = response;
                    this.addRecursedocumentCatetoriesChild(response);
                    // this.documentKeywordsMap.clear();
                    // for (const kw of response) {
                    //     const t =  new DocumentKeywords();
                    //     t.display = kw.name;
                    //     t.value = '/keywords/' + kw.id;
                    //     this.documentKeywords.push(t);
                    //     this.documentKeywordsMap.set(t.value, t);
                    // }

                    return this.documentCatetories;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        this.globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        this.globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );
    }

    public fetchBackups(): Observable<DocumentBackup[]> {

        const filter = '';
        const paramCC = '?';
        // if (groupId !== '' && groupId !== '-') {
        //     filter = '?TageinsGroupFilter=' + groupId;
        //     paramCC = '&';
        // }

        // if (filterAllowedRoles && filterAllowedRoles !== UserRole.None) {
        //     filter += paramCC + 'allowedRoles=' + filterAllowedRoles;
        // }
        return this.http
            .get<DocumentBackup[]>(this.authService.apiUrl + `backups${filter}`)
            .pipe(
                map(response => {
                    return response;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        this.globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        this.globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );
    }

    public fetchSetting(id: string): Observable<any[]> {

        const filter = '';
        const paramCC = '?';
        // if (groupId !== '' && groupId !== '-') {
        //     filter = '?TageinsGroupFilter=' + groupId;
        //     paramCC = '&';
        // }

        // if (filterAllowedRoles && filterAllowedRoles !== UserRole.None) {
        //     filter += paramCC + 'allowedRoles=' + filterAllowedRoles;
        // }
        return this.http
            .get<any>(this.authService.apiUrl + `settings/${id}`)
            .pipe(
                map(response => {
                    return response;
                }),
            );
    }

    public postSetting(id: string, data): Observable<any[]> {

        const putData: any = {};
        putData.identifier = id;
        putData.data = data;
        return this.http
            .post<any>(this.authService.apiUrl + `settings`, putData)
            .pipe(
                map(response => {
                     return response;
                }),
            );
    }

    public putSetting(setting): Observable<any[]> {
        return this.http
            .put<any>(this.authService.apiUrl + `settings/${setting.identifier}`, setting)
            .pipe(
                map(response => {
                    return response;
                }),
            );
    }

    public postBackup(): Observable<DocumentBackup> {
        return this.http.post<DocumentBackup>(this.authService.apiUrl + `backups`, JSON.stringify({}))
            .pipe(
                map(response => {
                   return response;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        this.globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        this.globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );


    }

    public deleteBackup(backup: DocumentBackup): Observable<DocumentBackup> {
        return this.http.delete<DocumentBackup>(this.authService.apiUrl + `backups/${backup.id}`)
            .pipe(
                map(response => {
                    return response;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        this.globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        this.globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );


    }

    public putOrPostDocumentCateory(cat: any): Observable<DocumentCategories> {
        if (cat.id) {
            return this.http.put<DocumentCategories>(this.authService.apiUrl + `categories/${cat.id}`, JSON.stringify(cat))
                .pipe(
                    map(response => {
                        return response;
                    }),
                    catchError(err => {
                        console.log(err);
                        if (err.error.detail) {
                            this.globalService.presentErrorToastNoTranslate(err.error.detail);
                        } else {
                            this.globalService.presentErrorToast('Something went wrong');
                        }
                        return throwError(err);
                    })
                );
        } else {
            return this.http.post<DocumentCategories>(this.authService.apiUrl + `categories`, JSON.stringify(cat))
                .pipe(
                    map(response => {
                        return response;
                    }),
                    catchError(err => {
                        console.log(err);
                        if (err.error.detail) {
                            this.globalService.presentErrorToastNoTranslate(err.error.detail);
                        } else {
                            this.globalService.presentErrorToast('Something went wrong');
                        }
                        return throwError(err);
                    })
                );
        }
    }

    public putOrPostDocumentKeyword(keyword: any): Observable<DocumentKeywords> {
        if (keyword.id) {
            return this.http.put<DocumentKeywords>(this.authService.apiUrl + `keywords/${keyword.id}`, JSON.stringify(keyword))
                .pipe(
                    map(response => {
                        return response;
                    }),
                    catchError(err => {
                        console.log(err);
                        if (err.error.detail) {
                            this.globalService.presentErrorToastNoTranslate(err.error.detail);
                        } else {
                            this.globalService.presentErrorToast('Something went wrong');
                        }
                        return throwError(err);
                    })
                );
        } else {
            return this.http.post<DocumentKeywords>(this.authService.apiUrl + `keywords`, JSON.stringify(keyword))
                .pipe(
                    map(response => {
                        return response;
                    }),
                    catchError(err => {
                        console.log(err);
                        if (err.error.detail) {
                            this.globalService.presentErrorToastNoTranslate(err.error.detail);
                        } else {
                            this.globalService.presentErrorToast('Something went wrong');
                        }
                        return throwError(err);
                    })
                );
        }
    }

    private addRecursedocumentCatetoriesChild(node: DocumentCategories, parent = 0) {
        const nodeToAdd = _.cloneDeep(node);
        delete nodeToAdd.children;
        if (nodeToAdd.id === 1) {
            nodeToAdd.name = '';
            this.documentCatetoriesMap.clear();
        }
        nodeToAdd.parent = parent + '';
        this.documentCatetoriesMap.set(nodeToAdd.id + '', nodeToAdd);
        if (Array.isArray(node.children) && node.children.length > 0) {
            for (const child of node.children) {
                this.addRecursedocumentCatetoriesChild(child, node.id);
            }
        }
    }

    public getDocumentCategoryBreadCrump(document: WlDocument): string {
        if (!document.category) {
            return '';
        }

        function recursiveDocCatName(categoryId: string, that): string {
            const cat = that.documentCatetoriesMap.get(categoryId);
            if (!cat) {
                return '';
            }
            if (cat.id === '1') {
                return '';
            }
            return recursiveDocCatName(cat.parent, that) + ' >' + cat.name;

        }

        return recursiveDocCatName(this.apiPlatformId(document.category), this).replace(' > >', '');
    }

    // private fetchRegisteredPrograms(groupId: string): Observable<RegisteredProgram[]> {
    //     let filter = '';
    //     if (groupId !== '' && groupId !== '-') {
    //         filter = '?program=' + groupId;
    //     }
    //     return this.http
    //         .get<RegisteredProgram[]>(this.authService.apiUrl + `registered_programs${filter}`).pipe(
    //             map(response => {
    //                this.registeredPrograms = response;
    //                return response;
    //             }));
    // }


    public fetchUser(id): Observable<UserModel> {
        return this.http
            .get<UserModel>(this.authService.apiUrl + `users/${id}`)
            .pipe(
                map(response => {
                    this.user = response as UserModel;
                    return this.user;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        this.globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        this.globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );
    }


    public validationIsUserDirty() {
        if (this._user == null) {
            return false;
        }
        return !_.isEqual(this._user, this._userClone);
    }

    public discardUser() {
        this._user = _.cloneDeep(this._userClone);
    }

    public validationIsDocumentDirty() {
        if (this._document == null) {
            return false;
        }
        return !_.isEqual(this._document, this._documentClone);
    }

    public discardDocument() {
        this._document = _.cloneDeep(this._documentClone);
    }


    public putCurrentUser(user = null): Observable<any> {
        if (user !== null) {
            this.user = user;
        }
        user = _.cloneDeep(this.user);
        delete user.settings;
        delete user.backgroundData;
        delete user.tstampLastUpdateAssessmentCompany;
        delete user.tstampLastUpdateAssessmentInvestor;
        delete user.tstampLastUpdateAssessmentMentor;
        delete user.tstampLastUpdateAssessmentUser;
        delete user.allowedRoles;
        delete user.roles;
        if (user.company && user.company.id) {
            user.company = '/companies/' + user.company.id;
            // user.company = '/companies/1';
        }
        // delete user.company;
        this.globalService.cleanNullPropertiesFromObject(user); // vor tageinsgroup, da null erhalten bleiben soll
        // user.username = user.email; // #12343  email als Benutzername verwenden: login email
        return this.http.put(this.authService.apiUrl + `users/${this.user.id}`, JSON.stringify(user))
            .pipe(
                map(response => {
                    this.user = response as UserModel;
                    if (this.user.id === this.authService.user.id) {
                        this.user.backgroundData = this.authService.user.backgroundData; // nur getCurrentUser liefert diese info
                        this.authService.user = this.user;
                    }
                    this.authService.fetchUsersNames().subscribe();
                    console.log(response);
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        this.globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        this.globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );


    }


    postUserAndGotoProfile(user: UserRole): Observable<any> {
        const now = moment().format('HH_mm_ss');
        const putData: any = {};
        putData.disable = true;
        putData.username = '';
        putData.email = '';
        putData.name = '';
        putData.roles = user;
        putData.allowedRoles = user;
        putData.password = 'newuser' + Math.random();
        return this.http.post(this.authService.apiUrl + `users`, JSON.stringify(putData))
            .pipe(
                map(response => {
                    console.log(response);
                    const resp = response as UserModel;
                    this.user = resp;
                    this._users = null;
                    this.router.navigate(['admin/profile', resp.id]);
                    // this.globalService.presentSuccessToast('Image change successful');
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        this.globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        this.globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );
    }


    /**
     * Upload image and set avatar img to user
     * Interceptor darf kein contenttype setzten
     */
    public uploadImage(image: File, userToSetAvatar: UserModel): Observable<any> {
        const formData = new FormData();

        // formData.append('file', image);
        formData.append('file', image, image.name);
        formData.append('user', this.userId);

        const token = this.authService ? this.authService.token : localStorage.getItem('token'); // fuer constructor call AuthService

        let headers = new HttpHeaders().set('Authorization', 'Bearer ' + token); // keinen contenttype, setzt formData selber
        headers = headers.append('Accept', 'application/json');

        return this.http.post(this.authService.apiUrl + 'avatar_objects', formData, {headers})
            .pipe(
                map(response => {
                    console.log(response);
                    const resp: any = response;
                    userToSetAvatar.urlAvatar = resp.contentUrl;
                    this.globalService.presentSuccessToast('Image change successful');
                }),
                catchError(err => {
                    this.globalService.presentErrorToast('Image change failed');
                    console.log(err);
                    return throwError(err);
                })
            );
    }


    public dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], {type: 'image/jpeg'});
        return blob;
    }

    /**
     * Upload files
     * Interceptor darf kein contenttype setzten
     */
    public uploadFiles(file: File, domain: string = '', description: string = '', idDocument): Observable<DocumentFile[]> {
        const formData = new FormData();

        // formData.append('file', image);
        formData.append('file', file, file.name);
        formData.append('domain', domain);
        formData.append('name', file.name);
        formData.append('description', description);
        formData.append('idDocument', idDocument);

        const token = this.authService ? this.authService.token : localStorage.getItem('token'); // fuer constructor call AuthService

        let headers = new HttpHeaders().set('Authorization', 'Bearer ' + token); // keinen contenttype, setzt formData selber
        headers = headers.append('Accept', 'application/json');

        return this.http.post<DocumentFile[]>(this.authService.apiUrl + 'file_objects', formData, {headers})
            .pipe(
                map(response => {
                    const doc = Object.assign(new WlDocument(), response);
                    this.document.files = doc.files;
                    return doc.files;
                }),
                catchError(err => {
                    console.log(err);
                    if (err.error.detail) {
                        this.globalService.presentErrorToastNoTranslate(err.error.detail);
                    } else {
                        this.globalService.presentErrorToast('Something went wrong');
                    }
                    return throwError(err);
                })
            );
    }

    public expandUser(user: UserModel) {
        for (const u of this._users) {
            u.expand = false;
        }
        user.expand = true;
    }

    getPictureObjectForDocumentPart(document: WlDocument, part: DocumentPart, idFile: number): DocumentFile {
        if (!part.data.idFileObject) {
            const isDirty = this.validationIsDocumentDirty();
            part.data.idFileObject = [];
            if  (!isDirty) {    // #13623 AJ: irgendwie springt er noch falsch im Browser...
                this.document = this.document; // falls repariert und davor nicht dirty -> nicht dirty
            }
        }
        if (part.data.idFileObject.length === 0) {
            return null;
        }

        for (const file of document.files) {
            if (file.id === idFile) {
                return file;
            }
        }
        return null;
    }

    getPicturePathForDocumentPart(document: WlDocument, part: DocumentPart, idFile: number = -1, small = false): string {
        if (idFile === -1 && part.data && part.data.idFileObject && Array.isArray(part.data.idFileObject)) {
            idFile = part.data.idFileObject[0];
        }

        const docFile = this.getPictureObjectForDocumentPart(document, part, idFile);
        if (!docFile) {
            return this.defaultImg;
        }
        return this.authService.apiUrl + 'media/documents/' + (small ? docFile.filenameWithRelPathSmall : docFile.filenameWithRelPath);

    }

    public documenteditModeAllowed() {
        if (this.authService.UserRole.Admin === this.authService.role || this.authService.UserRole.Leader === this.authService.role) {
            return true;
        }
        if (!this.document) {
            return false;
        }
        if (this.document.status !== 'DRAFT') {
            return false; // https://zp.informatik.tirol/issues/13401
        }
        return ((this.authService.user.id + '' === this.apiPlatformId(this.document.owner)));
    }

    public documentChangeStatusAllowed() {
        if (this.authService.UserRole.Admin === this.authService.role || this.authService.UserRole.Leader === this.authService.role) {
            return true;
        }
        if (!this.document) {
            return false;
        }
        if (((this.authService.user.id + '' !== this.apiPlatformId(this.document.owner)))) {
            return false;
        }
        if (this.document.status === 'APPROVED') {
            return false; // https://zp.informatik.tirol/issues/13401
        }
        return true;

    }

    getPartHtmlSanatized(part) {
        if (!part) {
            return '';
        }
        part = part.split('{{URL}}').join(this.authService.apiUrl); // #13579
        // console.log(this.part.html);
        return this.sanitizer.bypassSecurityTrustHtml(part);
    }

    // https://zp.informatik.tirol/issues/13396
    // downloadAndSavePicture(pictureUrl) {
    //     this.http.get(pictureUrl, {responseType: 'blob'})
    //         .subscribe((imageBlob: Blob) => {
    //             // imageBlob is the binary data of the the image
    //             // From here you can manipulate it and store it where you want
    //             // For example, to store it in your app dir
    //             // The replace true is optional but is just in case you want to overwrite it
    //             return this.file.writeFile(this.file.dataDirectory, "my_downloaded_image", imageBlob, {replace: true});
    //         }).toPromise();
    // }


    // downloadFile(fileURL) {
    //     let link = document.createElement('a');
    //     link.download = 'test.jpg';
    //     link.href = fileURL;
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //     link = null;
    // }
}
