import {environment} from '../../environments/environment';
import {IntentionModel} from './intention.model';

export enum UserRole {
    User = 'ROLE_USER',
    Admin = 'ROLE_ADMIN',
    Leader = 'ROLE_LEADER',
    None = 'ROLE_NONE'
}

export enum GroupState {
    closed = 'c',
    edit = 'e',
    active = 'a'
}

export class Company {
    id: number;
    name: string;
    contactPerson: string;
    phone: string;
    email: string;
    disable: boolean;

    // nicht persistente Felder
    expand: boolean; // button expand
}

export class Appointment {
    startDateString: string;
    tstampStart: number;

    tstampStartMidnight: number;
    duration = '15';
    label: string;

    get startDate(): Date {
        return new Date(this.tstampStart);
    }
}


export class TageinsGroup {
    id: number;
    name: string;
    disable: boolean;
    state: GroupState;
    appointments?: Appointment[];
    users?: UserModel[];
    leader?: UserModel;

    // nicht persistente Felder
    expand: boolean;
    targetPoints: number;
    sollPoints: number;
    maxPoints: number;
    // button expand
}

export interface BackgroundData {
    numUnreadMessages: number;
    numUnreadMessagesUsers: any;
    cameraSettings: any;

    // not persistent
    numUnreadMailbox: number;
}

export class SupervisorConfigModel {
    enabled = false;
}


export class UserModel {

    id: number;
    username: string;
    email: string;
    name: string;
    surName: string;
    nickname: string;
    jokerDay1: number;
    jokerDay2: number;
    isActive: boolean;
    roles: string[];
    allowedRoles: string[];
    settings?: any;
    mobile: string;
    urlAvatar: string;
    disable: boolean;
    messages: string[];
    points?: any;
    resetPasswordTstamp: number;

    company: Company;
    tageinsGroup: any;
    intentions: IntentionModel[];
    tstampRegistration: number;

    emailConfirmedTstamp: number;


    enabled = false;
    backgroundData: BackgroundData;
    password?: string;

    quota?: {};
    registeredEvents: any;


    // nicht persistente Felder
    expand: boolean; // button expand
    longName: string;

    public getAvatarImg(apiURL): string {
        return this.urlAvatar ? apiURL + this.urlAvatar : environment.defaultAvatarImg;
    }
}


export class Attachment {
    name: string;
    url: string;
    filename: string;
    domain: string;
    description: string;
    fileType: string;
    fileSize: string;
}


export interface FileUpload {
    id: number;
    user: string;
    filename: string;
    filenameWithRelPath: string;
    uploadPath: string;
    description: string;
    domain: string;
    name: string;
    tstamp: string;
}




