<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Dokument-Bilder</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()">{{'Abbrechen' | translate}}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <input class="ion-hide" type="file" accept="image/*"  #imageFileChooser multiple="multiple" name="filesToUpload[]">

    <ion-item-group class="ion-padding-vertical">
      <ion-item-divider>
        <ion-label>{{'Dokument-Bilder' | translate}}</ion-label>
        <ion-button slot="end" (click)="openHTMLImageFileChooser()">
          <ion-icon slot="icon-only" name="folder"></ion-icon>
        </ion-button>
        <ion-button slot="end" (click)="takePicture()" *ngIf="globalService.isApp">
          <ion-icon slot="icon-only" name="camera"></ion-icon>
        </ion-button>
      </ion-item-divider>
      <ng-container *ngIf="backendService.document.files">
        <ion-item *ngFor="let att of backendService.document.files.slice().reverse(); let i = index ">
<!--          <ion-label position="stacked">{{att.filename}}</ion-label>-->
          <img slot="start" [src]="authService.apiUrl + 'media/documents/'+ att.filenameWithRelPathSmall"
               class="docImagePreview" (click)="selectImageAndClose(att)" >
<!--          <ion-input type="text" [(ngModel)]="att.name"></ion-input>-->
<!--          <ion-button   fill="outline" slot="end"  color="tertiary" target="_blank" [href]="authService.apiUrl + 'media/documents/'+ att.filenameWithRelPath" download>-->
          <ion-button   fill="outline" slot="end"  color="tertiary" target="_blank" (click)="globalService.openWebpage( 'media/documents/'+ att.filenameWithRelPath, false, false)">
            <ion-icon slot="icon-only" color="tertiary" name="download"></ion-icon>
          </ion-button>
          <ion-button   fill="outline" slot="end"  color="secondary" target="_blank" (click)="rotateImage(att, i)" download>
            <ion-icon slot="icon-only" color="secondary" name="arrow-redo-outline"></ion-icon>
          </ion-button>
          <ion-button   fill="outline" slot="end" (click)="selectImageAndClose(att)">
            <ion-icon slot="icon-only" color="primary" name="checkmark"></ion-icon>
          </ion-button>

        </ion-item>
      </ng-container>
      <ion-item-divider>
      </ion-item-divider>
    </ion-item-group>
<!--  <pre>{{backendService.document.files | json}}</pre>-->
</ion-content>
