import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {MenuController, NavController, Platform, ToastController} from '@ionic/angular';
import {environment} from '../../environments/environment';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {Keyboard} from '@ionic-native/keyboard/ngx';
import {SafariViewController} from '@ionic-native/safari-view-controller/ngx';
import {UserRole} from '../models/user.model';


@Injectable({
    providedIn: 'root'
})
export class GlobalService {


    constructor(public toastController: ToastController,
                private menu: MenuController, private platform: Platform, public http: HttpClient,
                private translate: TranslateService, private keyboard: Keyboard,
                public safariViewController: SafariViewController,
                private rendererFactory: RendererFactory2, private navController: NavController
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);

        if (!localStorage.getItem('isTest')) {
            this.isTest = false;
        } else {
            this.isTest = localStorage.getItem('isTest') === 'true';
        }

        if (!localStorage.getItem('isDebug')) {
            this.isDebug = false;
        } else {
            this.isDebug = localStorage.getItem('isDebug') === 'true';
        }

        if (this.isTest) {
            this.displayPre = localStorage.getItem('displayPre') === 'true';
        }

        if (localStorage.getItem('theme')) {
            this._theme = localStorage.getItem('theme');
        }
        const role = localStorage.getItem('role');
        if (localStorage.getItem('splitPane')) {
            this.splitPane = localStorage.getItem('splitPane');
        }
    }

    get logoUrl(): string {
        return this._logoUrl;
    }


    get lang(): string {
        return this._lang;
    }


    set lang(value: string) {
        this._lang = value;
        moment.locale('de');
        localStorage.setItem('lang', value);
        this.translate.use(value);
    }


    get theme(): string {
        return this._theme;
    }

    set theme(value: string) {
        this._theme = value;
        localStorage.setItem('theme', value);
    }

    get splitPaneActive(): boolean {
        return this.splitPane === this._splitPaneBP;
    }

    set splitPaneActive(value: boolean) {
        this.splitPane = value ? this._splitPaneBP : false;
        localStorage.setItem('splitPane', this.splitPane);

    }


    private renderer: Renderer2;

    private _lang = 'en';
    private _theme = 'light';
    private webviewState = 'c';
    private _splitPaneBP = 'md';

    public defaultLang = 'de';
    public langMatcher = /de/; // /en|de/;
    langArray: Array<string> = ['de'];

    public isApp = false;
    public isIOS = false;
    public isAndroid = false;
    public isTest = true;
    public isDebug = false;
    public displayPre = false;
    public splitPane: any = false;


    // tslint:disable-next-line:variable-name
    private _logoUrl = 'assets/imgs/logo_inv.png';
    public appVersion: any = environment.APPVERSION;
    public weekDays = [
        {name: 'Monday', id: 1},
        {name: 'Tuesday', id: 2},
        {name: 'Wednesday', id: 3},
        {name: 'Thursday', id: 4},
        {name: 'Friday', id: 5},
        {name: 'Saturday', id: 6},
        {name: 'Sunday', id: 7},
    ];

    public countries = [
        {name: 'Austria', code: 'AT'},
        {name: 'Germany', code: 'DE'},
        {name: 'Afghanistan', code: 'AF'},
        {name: 'land Islands', code: 'AX'},
        {name: 'Albania', code: 'AL'},
        {name: 'Algeria', code: 'DZ'},
        {name: 'American Samoa', code: 'AS'},
        {name: 'AndorrA', code: 'AD'},
        {name: 'Angola', code: 'AO'},
        {name: 'Anguilla', code: 'AI'},
        {name: 'Antarctica', code: 'AQ'},
        {name: 'Antigua and Barbuda', code: 'AG'},
        {name: 'Argentina', code: 'AR'},
        {name: 'Armenia', code: 'AM'},
        {name: 'Aruba', code: 'AW'},
        {name: 'Australia', code: 'AU'},
        {name: 'Azerbaijan', code: 'AZ'},
        {name: 'Bahamas', code: 'BS'},
        {name: 'Bahrain', code: 'BH'},
        {name: 'Bangladesh', code: 'BD'},
        {name: 'Barbados', code: 'BB'},
        {name: 'Belarus', code: 'BY'},
        {name: 'Belgium', code: 'BE'},
        {name: 'Belize', code: 'BZ'},
        {name: 'Benin', code: 'BJ'},
        {name: 'Bermuda', code: 'BM'},
        {name: 'Bhutan', code: 'BT'},
        {name: 'Bolivia', code: 'BO'},
        {name: 'Bosnia and Herzegovina', code: 'BA'},
        {name: 'Botswana', code: 'BW'},
        {name: 'Bouvet Island', code: 'BV'},
        {name: 'Brazil', code: 'BR'},
        {name: 'British Indian Ocean Territory', code: 'IO'},
        {name: 'Brunei Darussalam', code: 'BN'},
        {name: 'Bulgaria', code: 'BG'},
        {name: 'Burkina Faso', code: 'BF'},
        {name: 'Burundi', code: 'BI'},
        {name: 'Cambodia', code: 'KH'},
        {name: 'Cameroon', code: 'CM'},
        {name: 'Canada', code: 'CA'},
        {name: 'Cape Verde', code: 'CV'},
        {name: 'Cayman Islands', code: 'KY'},
        {name: 'Central African Republic', code: 'CF'},
        {name: 'Chad', code: 'TD'},
        {name: 'Chile', code: 'CL'},
        {name: 'China', code: 'CN'},
        {name: 'Christmas Island', code: 'CX'},
        {name: 'Cocos (Keeling) Islands', code: 'CC'},
        {name: 'Colombia', code: 'CO'},
        {name: 'Comoros', code: 'KM'},
        {name: 'Congo', code: 'CG'},
        {name: 'Congo, The Democratic Republic of the', code: 'CD'},
        {name: 'Cook Islands', code: 'CK'},
        {name: 'Costa Rica', code: 'CR'},
        {name: 'Cote D\'Ivoire', code: 'CI'},
        {name: 'Croatia', code: 'HR'},
        {name: 'Cuba', code: 'CU'},
        {name: 'Cyprus', code: 'CY'},
        {name: 'Czech Republic', code: 'CZ'},
        {name: 'Denmark', code: 'DK'},
        {name: 'Djibouti', code: 'DJ'},
        {name: 'Dominica', code: 'DM'},
        {name: 'Dominican Republic', code: 'DO'},
        {name: 'Ecuador', code: 'EC'},
        {name: 'Egypt', code: 'EG'},
        {name: 'El Salvador', code: 'SV'},
        {name: 'Equatorial Guinea', code: 'GQ'},
        {name: 'Eritrea', code: 'ER'},
        {name: 'Estonia', code: 'EE'},
        {name: 'Ethiopia', code: 'ET'},
        {name: 'Falkland Islands (Malvinas)', code: 'FK'},
        {name: 'Faroe Islands', code: 'FO'},
        {name: 'Fiji', code: 'FJ'},
        {name: 'Finland', code: 'FI'},
        {name: 'France', code: 'FR'},
        {name: 'French Guiana', code: 'GF'},
        {name: 'French Polynesia', code: 'PF'},
        {name: 'French Southern Territories', code: 'TF'},
        {name: 'Gabon', code: 'GA'},
        {name: 'Gambia', code: 'GM'},
        {name: 'Georgia', code: 'GE'},
        {name: 'Ghana', code: 'GH'},
        {name: 'Gibraltar', code: 'GI'},
        {name: 'Greece', code: 'GR'},
        {name: 'Greenland', code: 'GL'},
        {name: 'Grenada', code: 'GD'},
        {name: 'Guadeloupe', code: 'GP'},
        {name: 'Guam', code: 'GU'},
        {name: 'Guatemala', code: 'GT'},
        {name: 'Guernsey', code: 'GG'},
        {name: 'Guinea', code: 'GN'},
        {name: 'Guinea-Bissau', code: 'GW'},
        {name: 'Guyana', code: 'GY'},
        {name: 'Haiti', code: 'HT'},
        {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
        {name: 'Holy See (Vatican City State)', code: 'VA'},
        {name: 'Honduras', code: 'HN'},
        {name: 'Hong Kong', code: 'HK'},
        {name: 'Hungary', code: 'HU'},
        {name: 'Iceland', code: 'IS'},
        {name: 'India', code: 'IN'},
        {name: 'Indonesia', code: 'ID'},
        {name: 'Iran, Islamic Republic Of', code: 'IR'},
        {name: 'Iraq', code: 'IQ'},
        {name: 'Ireland', code: 'IE'},
        {name: 'Isle of Man', code: 'IM'},
        {name: 'Israel', code: 'IL'},
        {name: 'Italy', code: 'IT'},
        {name: 'Jamaica', code: 'JM'},
        {name: 'Japan', code: 'JP'},
        {name: 'Jersey', code: 'JE'},
        {name: 'Jordan', code: 'JO'},
        {name: 'Kazakhstan', code: 'KZ'},
        {name: 'Kenya', code: 'KE'},
        {name: 'Kiribati', code: 'KI'},
        {name: 'Korea, Democratic Peoples Republic of', code: 'KP'},
        {name: 'Korea, Republic of', code: 'KR'},
        {name: 'Kuwait', code: 'KW'},
        {name: 'Kyrgyzstan', code: 'KG'},
        {name: 'Lao People\'S Democratic Republic', code: 'LA'},
        {name: 'Latvia', code: 'LV'},
        {name: 'Lebanon', code: 'LB'},
        {name: 'Lesotho', code: 'LS'},
        {name: 'Liberia', code: 'LR'},
        {name: 'Libyan Arab Jamahiriya', code: 'LY'},
        {name: 'Liechtenstein', code: 'LI'},
        {name: 'Lithuania', code: 'LT'},
        {name: 'Luxembourg', code: 'LU'},
        {name: 'Macao', code: 'MO'},
        {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
        {name: 'Madagascar', code: 'MG'},
        {name: 'Malawi', code: 'MW'},
        {name: 'Malaysia', code: 'MY'},
        {name: 'Maldives', code: 'MV'},
        {name: 'Mali', code: 'ML'},
        {name: 'Malta', code: 'MT'},
        {name: 'Marshall Islands', code: 'MH'},
        {name: 'Martinique', code: 'MQ'},
        {name: 'Mauritania', code: 'MR'},
        {name: 'Mauritius', code: 'MU'},
        {name: 'Mayotte', code: 'YT'},
        {name: 'Mexico', code: 'MX'},
        {name: 'Micronesia, Federated States of', code: 'FM'},
        {name: 'Moldova, Republic of', code: 'MD'},
        {name: 'Monaco', code: 'MC'},
        {name: 'Mongolia', code: 'MN'},
        {name: 'Montenegro', code: 'ME'},
        {name: 'Montserrat', code: 'MS'},
        {name: 'Morocco', code: 'MA'},
        {name: 'Mozambique', code: 'MZ'},
        {name: 'Myanmar', code: 'MM'},
        {name: 'Namibia', code: 'NA'},
        {name: 'Nauru', code: 'NR'},
        {name: 'Nepal', code: 'NP'},
        {name: 'Netherlands', code: 'NL'},
        {name: 'Netherlands Antilles', code: 'AN'},
        {name: 'New Caledonia', code: 'NC'},
        {name: 'New Zealand', code: 'NZ'},
        {name: 'Nicaragua', code: 'NI'},
        {name: 'Niger', code: 'NE'},
        {name: 'Nigeria', code: 'NG'},
        {name: 'Niue', code: 'NU'},
        {name: 'Norfolk Island', code: 'NF'},
        {name: 'Northern Mariana Islands', code: 'MP'},
        {name: 'Norway', code: 'NO'},
        {name: 'Oman', code: 'OM'},
        {name: 'Pakistan', code: 'PK'},
        {name: 'Palau', code: 'PW'},
        {name: 'Palestinian Territory, Occupied', code: 'PS'},
        {name: 'Panama', code: 'PA'},
        {name: 'Papua New Guinea', code: 'PG'},
        {name: 'Paraguay', code: 'PY'},
        {name: 'Peru', code: 'PE'},
        {name: 'Philippines', code: 'PH'},
        {name: 'Pitcairn', code: 'PN'},
        {name: 'Poland', code: 'PL'},
        {name: 'Portugal', code: 'PT'},
        {name: 'Puerto Rico', code: 'PR'},
        {name: 'Qatar', code: 'QA'},
        {name: 'Reunion', code: 'RE'},
        {name: 'Romania', code: 'RO'},
        {name: 'Russian Federation', code: 'RU'},
        {name: 'RWANDA', code: 'RW'},
        {name: 'Saint Helena', code: 'SH'},
        {name: 'Saint Kitts and Nevis', code: 'KN'},
        {name: 'Saint Lucia', code: 'LC'},
        {name: 'Saint Pierre and Miquelon', code: 'PM'},
        {name: 'Saint Vincent and the Grenadines', code: 'VC'},
        {name: 'Samoa', code: 'WS'},
        {name: 'San Marino', code: 'SM'},
        {name: 'Sao Tome and Principe', code: 'ST'},
        {name: 'Saudi Arabia', code: 'SA'},
        {name: 'Senegal', code: 'SN'},
        {name: 'Serbia', code: 'RS'},
        {name: 'Seychelles', code: 'SC'},
        {name: 'Sierra Leone', code: 'SL'},
        {name: 'Singapore', code: 'SG'},
        {name: 'Slovakia', code: 'SK'},
        {name: 'Slovenia', code: 'SI'},
        {name: 'Solomon Islands', code: 'SB'},
        {name: 'Somalia', code: 'SO'},
        {name: 'South Africa', code: 'ZA'},
        {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
        {name: 'Spain', code: 'ES'},
        {name: 'Sri Lanka', code: 'LK'},
        {name: 'Sudan', code: 'SD'},
        {name: 'Suriname', code: 'SR'},
        {name: 'Svalbard and Jan Mayen', code: 'SJ'},
        {name: 'Swaziland', code: 'SZ'},
        {name: 'Sweden', code: 'SE'},
        {name: 'Switzerland', code: 'CH'},
        {name: 'Syrian Arab Republic', code: 'SY'},
        {name: 'Taiwan, Province of China', code: 'TW'},
        {name: 'Tajikistan', code: 'TJ'},
        {name: 'Tanzania, United Republic of', code: 'TZ'},
        {name: 'Thailand', code: 'TH'},
        {name: 'Timor-Leste', code: 'TL'},
        {name: 'Togo', code: 'TG'},
        {name: 'Tokelau', code: 'TK'},
        {name: 'Tonga', code: 'TO'},
        {name: 'Trinidad and Tobago', code: 'TT'},
        {name: 'Tunisia', code: 'TN'},
        {name: 'Turkey', code: 'TR'},
        {name: 'Turkmenistan', code: 'TM'},
        {name: 'Turks and Caicos Islands', code: 'TC'},
        {name: 'Tuvalu', code: 'TV'},
        {name: 'Uganda', code: 'UG'},
        {name: 'Ukraine', code: 'UA'},
        {name: 'United Arab Emirates', code: 'AE'},
        {name: 'United Kingdom', code: 'GB'},
        {name: 'United States', code: 'US'},
        {name: 'United States Minor Outlying Islands', code: 'UM'},
        {name: 'Uruguay', code: 'UY'},
        {name: 'Uzbekistan', code: 'UZ'},
        {name: 'Vanuatu', code: 'VU'},
        {name: 'Venezuela', code: 'VE'},
        {name: 'Viet Nam', code: 'VN'},
        {name: 'Virgin Islands, British', code: 'VG'},
        {name: 'Virgin Islands, U.S.', code: 'VI'},
        {name: 'Wallis and Futuna', code: 'WF'},
        {name: 'Western Sahara', code: 'EH'},
        {name: 'Yemen', code: 'YE'},
        {name: 'Zambia', code: 'ZM'},
        {name: 'Zimbabwe', code: 'ZW'}
    ];

    // TODO AJ: DELETE
    public walletPages = [
        {
            title: 'Cards',
            url: '/delete/cards',
            icon: 'list'
        },
        {
            title: 'Dashboard',
            url: '/delete/dashboard',
            icon: 'list'
        },
        {
            title: 'Expense Overview',
            url: '/delete/expenseOverview',
            icon: 'list'
        },
        {
            title: 'Payment History',
            url: '/delete/paymentHistory',
            icon: 'list'
        },
        {
            title: 'Wallet',
            url: '/delete/wallet',
            icon: 'list'
        },
        {
            title: 'Wallet Alternative',
            url: '/delete/walletAlt',
            icon: 'list'
        }
    ];
    public organizerPages = [
        {
            title: 'Calendar',
            url: '/delete/calendar',
            icon: 'list'
        },
        {
            title: 'Day Overview',
            url: '/delete/dayOverview',
            icon: 'list'
        },
        {
            title: 'Schedule Day',
            url: '/delete/scheduleDay',
            icon: 'list'
        },
        {
            title: 'Schedule Months',
            url: '/delete/scheduleMonth',
            icon: 'list'
        }
    ];
    public introPages = [
        {
            title: 'Home',
            url: '/delete/home',
            icon: 'list'
        },
        {
            title: 'Home Alternative',
            url: '/delete/homeAlt',
            icon: 'list'
        },
        {
            title: 'Login',
            url: '/delete/login',
            icon: 'list'
        },
        {
            title: 'Login with Background',
            url: '/delete/loginBackground',
            icon: 'list'
        },
        {
            title: 'Login with Footer',
            url: '/delete/loginFooter',
            icon: 'list'
        }
    ];
    public walkthroughPages = [
        {
            title: 'Walkthrough',
            url: '/delete/walkthrough',
            icon: 'list'
        },
        {
            title: 'Walkthrough Alternative',
            url: '/delete/walkthroughAlt',
            icon: 'list'
        },
        {
            title: 'Walkthrough Page Slider',
            url: '/delete/walkthroughSlider',
            icon: 'list'
        }
    ];
    public profilePages = [
        {
            title: 'Profile Settings',
            url: '/delete/profileSettings',
            icon: 'list'
        },
        {
            title: 'Profile Settings Alternative',
            url: '/delete/alternativeProfile',
            icon: 'list'
        }
    ];
    public accountPages = [
        {
            title: 'Add an Account',
            url: '/delete/addAccount',
            icon: 'list'
        },
        {
            title: 'Sign Up',
            url: '/delete/signup',
            icon: 'list'
        },
        {
            title: 'Sign Up Alternative',
            url: '/delete/signupAlt',
            icon: 'list'
        }
    ];
    public formPages = [
        {
            title: 'Contact Form',
            url: '/delete/contactForm',
            icon: 'list'
        },
        {
            title: 'Contact Form Alternative',
            url: '/delete/contactFormAlt',
            icon: 'list'
        },
        {
            title: 'Inputs and Validation',
            url: '/delete/inputs',
            icon: 'list'
        }
    ];
    public cloudPages = [
        {
            title: 'Cloud',
            url: '/delete/cloud',
            icon: 'list'
        },
        {
            title: 'Mail',
            url: '/delete/mail',
            icon: 'list'
        },
        {
            title: 'Storage',
            url: '/delete/storage',
            icon: 'list'
        }
    ];
    public eventPages = [
        {
            title: 'Calendar Event',
            url: '/delete/calendarEvent',
            icon: 'list'
        },
        {
            title: 'Event Details',
            url: '/delete/eventDetails',
            icon: 'list'
        }
    ];
    public catalogPages = [
        {
            title: 'Albums',
            url: '/delete/albums',
            icon: 'list'
        },
        {
            title: 'Products',
            url: '/delete/products',
            icon: 'list'
        }
    ];
    public emptyStatePages = [
        {
            title: 'No Planned Events',
            url: '/delete/emptyeventsfirst',
            icon: 'list'
        },
        {
            title: 'No Trips',
            url: '/delete/emptyeventssecond',
            icon: 'list'
        },
        {
            title: 'No Events Here',
            url: '/delete/emptyeventsthird',
            icon: 'list'
        },
        {
            title: 'Nothing to do',
            url: '/delete/emptyeventsfourth',
            icon: 'list'
        },
        {
            title: 'No Trips',
            url: '/delete/emptyeventsfifth',
            icon: 'list'
        },
        {
            title: 'No Friends',
            url: '/delete/emptyfriendsfirst',
            icon: 'list'
        },
        {
            title: 'More Fun with Friends',
            url: '/delete/emptyfriendssecond',
            icon: 'list'
        },
        {
            title: 'Noone Around',
            url: '/delete/emptyfriendsthird',
            icon: 'list'
        },
        {
            title: 'Add Some Friends',
            url: '/delete/emptyfriendsfourth',
            icon: 'list'
        },
        {
            title: 'Better with Friends',
            url: '/delete/emptyfriendsfifth',
            icon: 'list'
        },
        {
            title: 'No Messages',
            url: '/delete/emptyinboxfirst',
            icon: 'list'
        },
        {
            title: 'No Chats',
            url: '/delete/emptyinboxsecond',
            icon: 'list'
        },
        {
            title: 'No mail',
            url: '/delete/emptyinboxthird',
            icon: 'list'
        },
        {
            title: 'Inbox Empty',
            url: '/delete/emptyinboxfourth',
            icon: 'list'
        },
        {
            title: 'No Notifications',
            url: '/delete/emptynotificationfirst',
            icon: 'list'
        },
        {
            title: 'All Caught Up',
            url: '/delete/emptynotificationsecond',
            icon: 'list'
        },
        {
            title: 'You Have None',
            url: '/delete/emptynotificationthird',
            icon: 'list'
        },
        {
            title: 'No Notifications',
            url: '/delete/emptynotificationfourth',
            icon: 'list'
        },
        {
            title: 'All Caught Up',
            url: '/delete/emptynotificationfifth',
            icon: 'list'
        },
        {
            title: 'No Photos',
            url: '/delete/emptyphotosfirst',
            icon: 'list'
        },
        {
            title: 'Take Some Pictures',
            url: '/delete/emptyphotossecond',
            icon: 'list'
        },
        {
            title: 'No Photos Here',
            url: '/delete/emptyphotosthird',
            icon: 'list'
        },
        {
            title: 'It\'s Lonely Here',
            url: '/delete/emptyphotosfourth',
            icon: 'list'
        },
        {
            title: 'You have no Pictures',
            url: '/delete/emptyphotosfifth',
            icon: 'list'
        },
        {
            title: 'No Purchases',
            url: '/delete/emptypurchasesfirst',
            icon: 'list'
        },
        {
            title: 'Buy Some Stuff',
            url: '/delete/emptypurchasessecond',
            icon: 'list'
        },
        {
            title: 'Nothing In Here',
            url: '/delete/emptypurchasesthird',
            icon: 'list'
        },
        {
            title: 'No Orders',
            url: '/delete/emptypurchasesfourth',
            icon: 'list'
        },
        {
            title: 'No Orders',
            url: '/delete/emptypurchasesfifth',
            icon: 'list'
        },
        {
            title: 'No Sent Mail',
            url: '/delete/emptysentfirst',
            icon: 'list'
        },
        {
            title: 'No Tasks Here',
            url: '/delete/emptytasksfirst',
            icon: 'list'
        },
        {
            title: 'No Tasks',
            url: '/delete/emptytaskssecond',
            icon: 'list'
        },
        {
            title: 'All Done',
            url: '/delete/emptytasksthird',
            icon: 'list'
        },
        {
            title: 'Nothing to do',
            url: '/delete/emptytasksfourth',
            icon: 'list'
        },
        {
            title: 'All done',
            url: '/delete/emptytasksfifth',
            icon: 'list'
        },
        {
            title: 'No Videos',
            url: '/delete/emptyvideosfirst',
            icon: 'list'
        },
        {
            title: 'Record Some Videos',
            url: '/delete/emptyvideossecond',
            icon: 'list'
        },
        {
            title: 'No Videos Here',
            url: '/delete/emptyvideosthird',
            icon: 'list'
        },
        {
            title: 'No Videos',
            url: '/delete/emptyvideosfourth',
            icon: 'list'
        },
        {
            title: 'Add some videos',
            url: '/delete/emptyvideosfifth',
            icon: 'list'
        },
        {
            title: 'Oops! No Credits',
            url: '/delete/emptywalletfirst',
            icon: 'list'
        },
        {
            title: 'Out of Credit',
            url: '/delete/emptywalletsecond',
            icon: 'list'
        },
        {
            title: 'No Payment Methods',
            url: '/delete/emptywalletthird',
            icon: 'list'
        },
        {
            title: 'No Payment Methods',
            url: '/delete/emptywalletfourth',
            icon: 'list'
        },
        {
            title: 'No Payment Methods',
            url: '/delete/emptywalletfifth',
            icon: 'list'
        }
    ];
    public componentPages = [
        {
            title: 'Buttons',
            url: '/delete/buttons',
            icon: 'list'
        },
        {
            title: 'Dropdowns',
            url: '/delete/dropdowns',
            icon: 'list'
        },
        {
            title: 'Toggles and Sliders',
            url: '/delete/selectors',
            icon: 'list'
        }
    ];
    public enhancedListPages = [
        {
            title: 'Buttons and Thumb List',
            url: '/delete/thumbButtonsList',
            icon: 'list'
        },
        {
            title: 'Buttons and Thumb List Alternative',
            url: '/delete/thumbButtonsListAlt',
            icon: 'list'
        },
        {
            title: 'Condensed List',
            url: '/delete/condensedList',
            icon: 'list'
        },
        {
            title: 'Condensed Sliding List',
            url: '/delete/condensedSlidingList',
            icon: 'list'
        },
        {
            title: 'Events List',
            url: '/delete/eventsList',
            icon: 'list'
        },
        {
            title: 'Grid Tiles',
            url: '/delete/gridTiles',
            icon: 'list'
        },
        {
            title: 'Ordered List',
            url: '/delete/orderedList',
            icon: 'list'
        },
        {
            title: 'Sliding List',
            url: '/delete/slidingList',
            icon: 'list'
        },
        {
            title: 'Thumbnail List',
            url: '/delete/listThumbStart',
            icon: 'list'
        },
        {
            title: 'Thumbnail List Clean',
            url: '/delete/listThumbCentered',
            icon: 'list'
        },
        {
            title: 'Thumbnail List Reversed',
            url: '/delete/listThumbEnd',
            icon: 'list'
        }
    ];
    public ionicListPages = [
        {
            title: 'Avatar List',
            url: '/delete/avatarList',
            icon: 'list'
        },
        {
            title: 'Basic List',
            url: '/delete/basicList',
            icon: 'list'
        },
        {
            title: 'Dividers List',
            url: '/delete/dividersList',
            icon: 'list'
        },
        {
            title: 'Headers List',
            url: '/delete/headersList',
            icon: 'list'
        },
        {
            title: 'Icon List',
            url: '/delete/iconList',
            icon: 'list'
        },
        {
            title: 'Inset List',
            url: '/delete/insetList',
            icon: 'list'
        },
        {
            title: 'Multi-line List',
            url: '/delete/multiLineList',
            icon: 'list'
        },
        {
            title: 'No Lines List',
            url: '/delete/noLinesList',
            icon: 'list'
        },
        {
            title: 'Sliding List',
            url: '/delete/altSlidingList',
            icon: 'list'
        },
        {
            title: 'Thumbnail List',
            url: '/delete/altThumbnailList',
            icon: 'list'
        }
    ];

    getCountryNameByCode(code) {
        if (!code) {
            return '';
        }
        const c = this.countries.find(x => x.code === code);
        return c ? c.name : '';
    }


    public addBodyClass(bodyClass) {
        this.renderer.addClass(document.body, bodyClass);
    }

    public removeBodyClass(bodyClass) {
        this.renderer.removeClass(document.body, bodyClass);
    }

    public setTheme(switchTheme) {
        if (switchTheme) {
            this.theme = this._theme === 'light' ? 'dark' : 'light';
        }
        if (this._theme === 'dark') {
            this.addBodyClass('dark-theme');
            this.removeBodyClass('light-theme');
        } else {
            this.addBodyClass('light-theme');
            this.removeBodyClass('dark-theme');
        }


    }

    convertDateToUnixTimeStamp(date: Date) {
        return Math.round(date.getTime() / 1000);
    }

    convertUnixTimeStamp(time) {
        const a = time * 1000;
        return a;
    }

    renderUnixTimeStamp(ts) {
        return moment.unix(ts).format('Do MMM HH:mm');
    }


    timeStampToIsoString(tsInMs) {
        return (new Date(tsInMs)).toISOString();
    }

    toggleTest() {
        this.isTest = !this.isTest;
        localStorage.setItem('isTest', String(this.isTest));
    }

    toggleSplitePane() {
        // https://ionicframework.com/docs/api/split-pane
        this.splitPane = !this.splitPane ? this._splitPaneBP : false;
        localStorage.setItem('splitPane', this.splitPane);
    }

    /**
     * Toast + translate
     */
    async presentToast(msg, duration = 4000) {
        msg = this.translate.instant(msg);
        const toast = await this.toastController.create({
            message: msg,
            duration,
            color: 'secondary',
            buttons: [
                {
                    text: 'OK',
                    role: 'cancel',
                    handler: () => {
                        console.log('Close clicked');
                    }
                }
            ]
        });
        toast.present();
    }

    /**
     * Toast + translate
     */
    async presentSuccessToast(msg, duration = 2000) {
        msg = this.translate.instant(msg);
        const toast = await this.toastController.create({
            message: msg,
            duration,
            color: 'success',
            buttons: [
                {
                    text: 'OK',
                    role: 'cancel',
                    handler: () => {
                        console.log('Close clicked');
                    }
                }
            ]
        });
        toast.present();
    }

    /**
     * Error-Toast + translate
     */
    async presentErrorToast(msg, duration = 10000) {
        msg = this.translate.instant(msg);
        const toast = await this.toastController.create({
            message: msg,
            color: 'danger',
            duration,
            buttons: [
                {
                    text: 'OK',
                    role: 'cancel',
                    handler: () => {
                        console.log('Close clicked');
                    }
                }
            ]
        });
        toast.present();
    }


    async presentErrorToastNoTranslate(msg, duration = 10000) {
        const toast = await this.toastController.create({
            message: msg,
            color: 'danger',
            duration,
            buttons: [
                {
                    text: 'OK',
                    role: 'cancel',
                    handler: () => {
                        console.log('Close clicked');
                    }
                }
            ]
        });

        toast.present();
    }

    // logout() {
    //     // Api Token Logout
    //     localStorage.removeItem('token');
    //     localStorage.removeItem('role');
    //     localStorage.removeItem('apiUrl');
    //     this._user = null;
    //     // this.menu.enable(false);//
    //     // window.location.reload(); //backToWelcome funktioniert nicht zuverläassig
    //     this.navCtrl.navigateRoot('/welcome');
    //
    // }


    compareFnKey(option1: any, option2: any) {
        return option1.key === option2.key;
    }

    compareFnId(option1: any, option2: any) {
        return option1.id === option2.id;
    }

    reloadApp() {
        this.navController.navigateRoot('/tabs').then(() => {
            setTimeout(() => {
                    window.location.reload();
                },
                1500);

        });
    }

    /**
     * deprecated
     */
    // openMainMenu() {
    //     this.menu.enable(true, 'first');
    //     this.menu.open('first');
    //     this.activeMenu = 'first';
    // }

    openActiveMenu() {
        this.menu.enable(true, 'menu');
        this.menu.open('menu');
    }

    openDebugMenu() {
        // if (this.isDebug) {
        this.menu.enable(true, 'debug');
        this.menu.open('debug');
        // }
    }

    openDesignTemplateMenu() {
        this.menu.enable(true, 'delete');
        this.menu.open('delete');
    }


    /**
     * TODO AJ
     */
    addUserTask() {
        // if (this.countUserTasks == "") {
        //     this.countUserTasks = 1;
        // }
        // else {
        //     this.countUserTasks = Number(this.countUserTasks) + 1;
        // }
        // this.setBadge();
        // this.events.publish('user:task', this.countUserTasks, Date.now());

    }


    public keyboardHide() {
        if (this.isApp) {
            this.keyboard.hide();
        }
    }

    /**
     * Öffnet eine Webpage
     * @param url URL
     * @param withAutomaticLogin Automatischer Login: Wenn gesetzt, kann kein externe ULR aufgerufen werden
     * @param externalLink Eine externe Seite, Base URL wird nicht hinzufgefügt.
     */
    openWebpage(url: string, withAutomaticLogin: boolean = false, externalLink: boolean = false, windowRef = null) {
        const apiUrl = localStorage.getItem('apiUrl');
        if (withAutomaticLogin) {
            const data = JSON.parse(localStorage.getItem('userData'));
            const token = data.data.token;
            url = apiUrl + environment.AUTOLOGIN_PATH + '?targetUrl=' + encodeURIComponent(url) + '&token=' + token;
        }
        if (!withAutomaticLogin && !externalLink) {
            url = apiUrl + url;
        }

        if (!this.isApp) {
            if (windowRef) { // damit kein popup (time click to open page muss klein sein)
                windowRef.location.href = url;
            } else {
                const win = window.open(url, '_blank');
                win.focus();
            }
            return;
        }

        this.safariViewController.isAvailable()
            .then((available: boolean) => {
                    if (available) {

                        const os = this.safariViewController.show({
                            url,
                            hidden: false,
                            animated: false,
                            transition: 'curl',
                            enterReaderModeIfAvailable: false,
                            tintColor: '#ffffff',
                            barColor: '#000000',
                            controlTintColor: '#ffffff',
                        });
                        os.subscribe((result: any) => {
                                if (result.event === 'opened') {
                                    console.log('Opened');
                                    this.webviewState = 'o';
                                } else if (result.event === 'loaded') {
                                    console.log('loaded');
                                    this.webviewState = 'l';
                                } else if (result.event === 'closed') {
                                    console.log('closed');
                                    // if (this.webviewState === 'l') { // wird sonst mehrmals gefeuert
                                    //     this.events.publish('safariViewControllerClosed');
                                    // }
                                }

                            },
                            (error: any) => console.error(error)
                        );
                        return;


                    } else {
                        return null;
                    }
                }
            );

    }


    openWebPageTranslateURL(urlKey) {
        this.translate.get(urlKey).subscribe(url => {
            this.openWebpage(url, false, true);
        });
    }

    onChange(e) {
        console.log(e);
    }

    environment() {
        return environment;
    }

    keyAscOrderComparator = (a: any, b: any): number => {
        return a.value.key > b.value.key ? 1 : (b.value.key > a.value.key ? -1 : 0);
    };

    /**
     * Entfernt null Properties
     */
    public cleanNullPropertiesFromObject(obj) {
        for (const propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
    }


}
