import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuard} from './guard/auth.guard';
import {RoleGuard} from './guard/role.guard';
import {UserRole} from './models/user.model';

const routes: Routes = [

  // { path: 'delete/addAccount', loadChildren: './delete/add-account/add-account.module#AddAccountPageModule' },
  // { path: 'delete/albums', loadChildren: './delete/albums/albums.module#AlbumsPageModule' },
  // { path: 'delete/altSlidingList', loadChildren: './delete/alt-sliding-list/alt-sliding-list.module#AltSlidingListPageModule' },
  // { path: 'delete/altThumbnailList', loadChildren: './delete/alt-thumbnail-list/alt-thumbnail-list.module#AltThumbnailListPageModule' },
  // { path: 'delete/alternativeProfile', loadChildren: './delete/alternative-profile/alternative-profile.module#AlternativeProfilePageModule' },
  // { path: 'delete/avatarList', loadChildren: './delete/avatar-list/avatar-list.module#AvatarListPageModule' },
  // { path: 'delete/basicList', loadChildren: './delete/basic-list/basic-list.module#BasicListPageModule' },
  // { path: 'delete/buttons', loadChildren: './delete/buttons/buttons.module#ButtonsPageModule' },
  // { path: 'delete/calendar', loadChildren: './delete/calendar/calendar.module#CalendarPageModule' },
  // { path: 'delete/calendarEvent', loadChildren: './delete/calendar-event/calendar-event.module#CalendarEventPageModule' },
  // { path: 'delete/cards', loadChildren: './delete/cards/cards.module#CardsPageModule' },
  // { path: 'delete/cloud', loadChildren: './delete/cloud/cloud.module#CloudPageModule' },
  // { path: 'delete/condensedList', loadChildren: './delete/condensed-list/condensed-list.module#CondensedListPageModule' },
  // { path: 'delete/condensedSlidingList', loadChildren: './delete/condensed-sliding-list/condensed-sliding-list.module#CondensedSlidingListPageModule' },
  // { path: 'delete/contactForm', loadChildren: './delete/contact-form/contact-form.module#ContactFormPageModule' },
  // { path: 'delete/contactFormAlt', loadChildren: './delete/contact-form-alt/contact-form-alt.module#ContactFormAltPageModule' },
  // { path: 'delete/dashboard', loadChildren: './delete/dashboard/dashboard.module#DashboardPageModule' },
  // { path: 'delete/dayOverview', loadChildren: './delete/day-overview/day-overview.module#DayOverviewPageModule' },
  // { path: 'delete/dividersList', loadChildren: './delete/dividers-list/dividers-list.module#DividersListPageModule' },
  // { path: 'delete/dropdowns', loadChildren: './delete/dropdowns/dropdowns.module#DropdownsPageModule' },
  // { path: 'delete/emptyeventsfirst', loadChildren: './delete/empty-events-first/empty-events-first.module#EmptyEventsFirstPageModule' },
  // { path: 'delete/emptyeventssecond', loadChildren: './delete/empty-events-second/empty-events-second.module#EmptyEventsSecondPageModule' },
  // { path: 'delete/emptyeventsthird', loadChildren: './delete/empty-events-third/empty-events-third.module#EmptyEventsThirdPageModule' },
  // { path: 'delete/emptyeventsfourth', loadChildren: './delete/empty-events-fourth/empty-events-fourth.module#EmptyEventsFourthPageModule' },
  // { path: 'delete/emptyeventsfifth', loadChildren: './delete/empty-events-fifth/empty-events-fifth.module#EmptyEventsFifthPageModule' },
  // { path: 'delete/emptyinboxfirst', loadChildren: './delete/empty-inbox-first/empty-inbox-first.module#EmptyInboxFirstPageModule' },
  // { path: 'delete/emptyinboxsecond', loadChildren: './delete/empty-inbox-second/empty-inbox-second.module#EmptyInboxSecondPageModule' },
  // { path: 'delete/emptyinboxthird', loadChildren: './delete/empty-inbox-third/empty-inbox-third.module#EmptyInboxThirdPageModule' },
  // { path: 'delete/emptyinboxfourth', loadChildren: './delete/empty-inbox-fourth/empty-inbox-fourth.module#EmptyInboxFourthPageModule' },
  // { path: 'delete/emptyfriendsfirst', loadChildren: './delete/empty-friends-first/empty-friends-first.module#EmptyFriendsFirstPageModule' },
  // { path: 'delete/emptyfriendssecond', loadChildren: './delete/empty-friends-second/empty-friends-second.module#EmptyFriendsSecondPageModule' },
  // { path: 'delete/emptyfriendsthird', loadChildren: './delete/empty-friends-third/empty-friends-third.module#EmptyFriendsThirdPageModule' },
  // { path: 'delete/emptyfriendsfourth', loadChildren: './delete/empty-friends-fourth/empty-friends-fourth.module#EmptyFriendsFourthPageModule' },
  // { path: 'delete/emptyfriendsfifth', loadChildren: './delete/empty-friends-fifth/empty-friends-fifth.module#EmptyFriendsFifthPageModule' },
  // { path: 'delete/emptynotificationfirst', loadChildren: './delete/empty-notification-first/empty-notification-first.module#EmptyNotificationFirstPageModule' },
  // { path: 'delete/emptynotificationsecond', loadChildren: './delete/empty-notification-second/empty-notification-second.module#EmptyNotificationSecondPageModule' },
  // { path: 'delete/emptynotificationthird', loadChildren: './delete/empty-notification-third/empty-notification-third.module#EmptyNotificationThirdPageModule' },
  // { path: 'delete/emptynotificationfourth', loadChildren: './delete/empty-notification-fourth/empty-notification-fourth.module#EmptyNotificationFourthPageModule' },
  // { path: 'delete/emptynotificationfifth', loadChildren: './delete/empty-notification-fifth/empty-notification-fifth.module#EmptyNotificationFifthPageModule' },
  // { path: 'delete/emptyphotosfirst', loadChildren: './delete/empty-photos-first/empty-photos-first.module#EmptyPhotosFirstPageModule' },
  // { path: 'delete/emptyphotossecond', loadChildren: './delete/empty-photos-second/empty-photos-second.module#EmptyPhotosSecondPageModule' },
  // { path: 'delete/emptyphotosthird', loadChildren: './delete/empty-photos-third/empty-photos-third.module#EmptyPhotosThirdPageModule' },
  // { path: 'delete/emptyphotosfourth', loadChildren: './delete/empty-photos-fourth/empty-photos-fourth.module#EmptyPhotosFourthPageModule' },
  // { path: 'delete/emptyphotosfifth', loadChildren: './delete/empty-photos-fifth/empty-photos-fifth.module#EmptyPhotosFifthPageModule' },
  // { path: 'delete/emptypurchasesfirst', loadChildren: './delete/empty-purchases-first/empty-purchases-first.module#EmptyPurchasesFirstPageModule' },
  // { path: 'delete/emptypurchasessecond', loadChildren: './delete/empty-purchases-second/empty-purchases-second.module#EmptyPurchasesSecondPageModule' },
  // { path: 'delete/emptypurchasesthird', loadChildren: './delete/empty-purchases-third/empty-purchases-third.module#EmptyPurchasesThirdPageModule' },
  // { path: 'delete/emptypurchasesfourth', loadChildren: './delete/empty-purchases-fourth/empty-purchases-fourth.module#EmptyPurchasesFourthPageModule' },
  // { path: 'delete/emptypurchasesfifth', loadChildren: './delete/empty-purchases-fifth/empty-purchases-fifth.module#EmptyPurchasesFifthPageModule' },
  // { path: 'delete/emptysentfirst', loadChildren: './delete/empty-sent-first/empty-sent-first.module#EmptySentFirstPageModule' },
  // { path: 'delete/emptytasksfirst', loadChildren: './delete/empty-tasks-first/empty-tasks-first.module#EmptyTasksFirstPageModule' },
  // { path: 'delete/emptytaskssecond', loadChildren: './delete/empty-tasks-second/empty-tasks-second.module#EmptyTasksSecondPageModule' },
  // { path: 'delete/emptytasksthird', loadChildren: './delete/empty-tasks-third/empty-tasks-third.module#EmptyTasksThirdPageModule' },
  // { path: 'delete/emptytasksfourth', loadChildren: './delete/empty-tasks-fourth/empty-tasks-fourth.module#EmptyTasksFourthPageModule' },
  // { path: 'delete/emptytasksfifth', loadChildren: './delete/empty-tasks-fifth/empty-tasks-fifth.module#EmptyTasksFifthPageModule' },
  // { path: 'delete/emptyvideosfirst', loadChildren: './delete/empty-videos-first/empty-videos-first.module#EmptyVideosFirstPageModule' },
  // { path: 'delete/emptyvideossecond', loadChildren: './delete/empty-videos-second/empty-videos-second.module#EmptyVideosSecondPageModule' },
  // { path: 'delete/emptyvideosthird', loadChildren: './delete/empty-videos-third/empty-videos-third.module#EmptyVideosThirdPageModule' },
  // { path: 'delete/emptyvideosfourth', loadChildren: './delete/empty-videos-fourth/empty-videos-fourth.module#EmptyVideosFourthPageModule' },
  // { path: 'delete/emptyvideosfifth', loadChildren: './delete/empty-videos-fifth/empty-videos-fifth.module#EmptyVideosFifthPageModule' },
  // { path: 'delete/emptywalletfirst', loadChildren: './delete/empty-wallet-first/empty-wallet-first.module#EmptyWalletFirstPageModule' },
  // { path: 'delete/emptywalletsecond', loadChildren: './delete/empty-wallet-second/empty-wallet-second.module#EmptyWalletSecondPageModule' },
  // { path: 'delete/emptywalletthird', loadChildren: './delete/empty-wallet-third/empty-wallet-third.module#EmptyWalletThirdPageModule' },
  // { path: 'delete/emptywalletfourth', loadChildren: './delete/empty-wallet-fourth/empty-wallet-fourth.module#EmptyWalletFourthPageModule' },
  // { path: 'delete/emptywalletfifth', loadChildren: './delete/empty-wallet-fifth/empty-wallet-fifth.module#EmptyWalletFifthPageModule' },
  // { path: 'delete/eventDetails', loadChildren: './delete/event-details/event-details.module#EventDetailsPageModule' },
  // { path: 'delete/eventsList', loadChildren: './delete/events-list/events-list.module#EventsListPageModule' },
  // { path: 'delete/expenseOverview', loadChildren: './delete/expense-overview/expense-overview.module#ExpenseOverviewPageModule' },
  // { path: 'delete/gridTiles', loadChildren: './delete/grid-tiles/grid-tiles.module#GridTilesPageModule' },
  // { path: 'delete/headersList', loadChildren: './delete/headers-list/headers-list.module#HeadersListPageModule' },
  // { path: 'delete/homeAlt', loadChildren: './delete/home-alt/home-alt.module#HomeAltPageModule' },
  // { path: 'delete/iconList', loadChildren: './delete/icon-list/icon-list.module#IconListPageModule' },
  // { path: 'delete/inputs', loadChildren: './delete/inputs/inputs.module#InputsPageModule' },
  // { path: 'delete/insetList', loadChildren: './delete/inset-list/inset-list.module#InsetListPageModule' },
  // { path: 'delete/listThumbCentered', loadChildren: './delete/list-thumb-centered/list-thumb-centered.module#ListThumbCenteredPageModule' },
  // { path: 'delete/listThumbEnd', loadChildren: './delete/list-thumb-end/list-thumb-end.module#ListThumbEndPageModule' },
  // { path: 'delete/listThumbStart', loadChildren: './delete/list-thumb-start/list-thumb-start.module#ListThumbStartPageModule' },
  // { path: 'delete/login', loadChildren: './delete/login/login.module#LoginPageModule' },
  // { path: 'delete/loginBackground', loadChildren: './delete/login-background/login-background.module#LoginBackgroundPageModule' },
  // { path: 'delete/loginFooter', loadChildren: './delete/login-footer/login-footer.module#LoginFooterPageModule' },
  // { path: 'delete/mail', loadChildren: './delete/mail/mail.module#MailPageModule' },
  // { path: 'delete/multiLineList', loadChildren: './delete/multi-line-list/multi-line-list.module#MultiLineListPageModule' },
  // { path: 'delete/noLinesList', loadChildren: './delete/no-lines-list/no-lines-list.module#NoLinesListPageModule' },
  // { path: 'delete/orderedList', loadChildren: './delete/ordered-list/ordered-list.module#OrderedListPageModule' },
  // { path: 'delete/paymentHistory', loadChildren: './delete/payment-history/payment-history.module#PaymentHistoryPageModule' },
  // { path: 'delete/products', loadChildren: './delete/products/products.module#ProductsPageModule' },
  // { path: 'delete/profileSettings', loadChildren: './delete/profile-settings/profile-settings.module#ProfileSettingsPageModule' },
  // { path: 'delete/scheduleDay', loadChildren: './delete/schedule-day/schedule-day.module#ScheduleDayPageModule' },
  // { path: 'delete/scheduleMonth', loadChildren: './delete/schedule-month/schedule-month.module#ScheduleMonthPageModule' },
  // { path: 'delete/selectors', loadChildren: './delete/selectors/selectors.module#SelectorsPageModule' },
  // { path: 'delete/signup', loadChildren: './delete/signup/signup.module#SignupPageModule' },
  // { path: 'delete/signupAlt', loadChildren: './delete/signup-alt/signup-alt.module#SignupAltPageModule' },
  // { path: 'delete/slidingList', loadChildren: './delete/sliding-list/sliding-list.module#SlidingListPageModule' },
  // { path: 'delete/storage', loadChildren: './delete/storage/storage.module#StoragePageModule' },
  // { path: 'delete/thumbButtonsList', loadChildren: './delete/thumb-buttons-list/thumb-buttons-list.module#ThumbButtonsListPageModule' },
  // { path: 'delete/thumbButtonsListAlt', loadChildren: './delete/thumb-buttons-list-alt/thumb-buttons-list-alt.module#ThumbButtonsListAltPageModule' },
  // { path: 'delete/walkthrough', loadChildren: './delete/walkthrough/walkthrough.module#WalkthroughPageModule' },
  // { path: 'delete/walkthroughAlt', loadChildren: './delete/walkthrough-alt/walkthrough-alt.module#WalkthroughAltPageModule' },
  // { path: 'delete/walkthroughSlider', loadChildren: './delete/walkthrough-slider/walkthrough-slider.module#WalkthroughSliderPageModule' },
  // { path: 'delete/wallet', loadChildren: './delete/wallet/wallet.module#WalletPageModule' },
  // { path: 'delete/home', loadChildren: './delete/home/home.module#HomePageModule'},
  // { path: 'delete/walletAlt', loadChildren: './delete/wallet-alt/wallet-alt.module#WalletAltPageModule' },

  { path: '', loadChildren: () => import('./page/tabs/tabs.module').then(m => m.TabsPageModule), canActivate: [AuthGuard]  },
  { path: 'login', loadChildren: () => import('./page/login/login.module').then(m => m.LoginPageModule) },
  { path: 'home', loadChildren: () => import('./page/home/home.module').then(m => m.HomePageModule) },
  { path: 'home/:searchStatus', loadChildren: () => import('./page/home/home.module').then(m => m.HomePageModule) },
  { path: 'intention/:userIndexId', loadChildren: () => import('./page/home/home.module').then(m => m.HomePageModule) },
  { path: 'welcome', loadChildren: () => import('./page/welcome/welcome.module').then(m => m.WelcomePageModule) },
  { path: 'chat', loadChildren: () => import('./page/chat/chat.module').then(m => m.ChatPageModule) },
  { path: 'analysis', loadChildren: () => import('./page/analysis/analysis.module').then(m => m.AnalysisPageModule) },
  { path: 'about', loadChildren: () => import('./page/about/about.module').then(m => m.AboutPageModule) },


  {path: 'my-profile', loadChildren: () => import('./page/my-profile/my-profile.module').then(m => m.MyProfilePageModule), canActivate: [AuthGuard]},

  {path: 'admin/profile/:userId', loadChildren: () => import('./page/my-profile/my-profile.module').then(m => m.MyProfilePageModule), canActivate: [AuthGuard]},
  {
    path: 'my-profile-edit',
    loadChildren: () => import('./page/my-profile-edit/my-profile-edit.module').then(m => m.MyProfileEditPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/profile-edit/:userId',
    loadChildren: () => import('./page/my-profile-edit/my-profile-edit.module').then(m => m.MyProfileEditPageModule),
    canActivate: [AuthGuard]
  },
  {path: 'reset-password', loadChildren: () => import('./page/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule), canActivate: [AuthGuard]},
  {
    path: 'reset-password/:user/:uuid',
    loadChildren: () => import('./page/forgot-password-step2/forgot-password-step2.module').then(m => m.ForgotPasswordStep2PageModule)
  },
  {
    path: 'admin/reset-password/:userId',
    loadChildren: () => import('./page/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule),
    canActivate: [AuthGuard]
  },
  {path: 'forgot-password', loadChildren: () => import('./page/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)},
  { path: 'my-documents', loadChildren: () => import('./page/my-documents/my-documents.module').then(m => m.MyDocumentsPageModule) },
  { path: 'document/:documentId', loadChildren: () => import('./page/document/document.module').then(m => m.DocumentPageModule) },
  { path: 'document-part/:documentId/:partId', loadChildren: () => import('./page/document-part/document-part.module').then(m => m.DocumentPartPageModule) },
  { path: 'draft-documents', loadChildren: () => import('./page/draft-documents/draft-documents.module').then(m => m.DraftDocumentsPageModule) },
  { path: 'deleted-documents', loadChildren: () => import('./page/deleted-documents/deleted-documents.module').then(m => m.DeletedDocumentsPageModule) },
  { path: 'category/:catId', loadChildren: () => import('./page/admin/category-panel/category-panel.module').then(m => m.CategoryPanelPageModule) },
  { path: 'keyword/:keywordId', loadChildren: () => import('./page/admin/keyword-panel/keyword-panel.module').then(m => m.KeywordPanelPageModule) },
  {
    path: 'camera-setting',
    loadChildren: () => import('./page/admin/camera-setting/camera-setting.module').then(m => m.CameraSettingPageModule),
    canActivate: [AuthGuard]
  },

];

// 404 Wenn App in Web Browser reloaded useHash: true
// Wenn viele Seiten: NoPreloading
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
