// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  COMPANY: 'wohllaib',
  URL: 'api.wohllaib.informatik.tirol/',
  TEST_APP_URL: 'https://client.wohllaib.informatik.tirol',
  APP_URL: 'https://client.wohllaib.informatik.tirol',
  TESTURLAJ: '10.0.0.42/',
  TESTURLLT: 'local.wohllaib.at/',
  TESTURL_WOHLLAIB: 'test.api.wohllaib.informatik.tirol/',
  REST_PATH: '',
  AUTOLOGIN_PATH: 'autologin.html',
  beURL: '',
  APPVERSION: '1.0.9',
  theme: 'dark', // TODO AJ: delete -> pages anpassen
  companyUserImg: 'assets/imgs/avatar-iect.png',
  defaultAvatarImg: 'assets/imgs/placeholder-profile.jpg',
  defaultImg: 'assets/imgs/empty-photos-1.png',
  defaultCalendarImg: 'assets/imgs/avatar-calendar.png',
  defaultAssessmentImg: 'assets/imgs/avatarAssessment.png',
  amDateFormat: 'DD.MM.YYYY',
  amDateTimeFormat: 'DD-MM-YYYY HH:mm',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
